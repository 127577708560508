import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  active: {
    color: theme.palette.primary.main
  },
}));

export default Styles;
