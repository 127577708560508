import { Login, ForgotPassword, PublicIncendio } from '~/pages'

import { SwitchRoutes } from './SwitchRoutes'

export const authRoutes = [
  {
    label: 'Seguro Incêndio – Acesso sem Login',
    path: '/simulacao-incendio',
    exact: true,
    Component: PublicIncendio,
    hide: true
  },
  {
    label: 'Fazer Login',
    path: '/login',
    exact: true,
    Component: Login
  },
  {
    label: 'Recuperação de Senha',
    path: '/recuperar-senha',
    exact: true,
    Component: ForgotPassword,
    hide: true
  },
]

export default () => SwitchRoutes(authRoutes)
