import React from 'react';

import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import logo from '~/assets/logo-white.png';

import styles from './styles';
import { Navigation } from './Navigation';

export function AppBar() {
  const classes = styles();

  return (
    <MaterialAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>

        <div className={ classes.title }>
          <img src={ logo } alt="TDA Seguros" className={ classes.logo }/>
        </div>

        <Navigation />
      </Toolbar>
    </MaterialAppBar>
  );
}
