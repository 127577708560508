import React, { useState, useEffect, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { LoadingButton } from '~/components';
import { useStore } from '~/store';

import useStyles from './styles';
import { ResultadoSimulacaoTable } from './ResultadoSimulacaoTable';
import ModalCotacao from '~/components/ModalCotacao';

export function ResultadoSimulacao({ activeStep, setActiveStep, handleNext }) {
  const logged = localStorage.getItem('@TDA_token');

  const { simulacao, alert } = useStore();

  const [selected, setSelected] = useState();

  const [ getting, setGetting ] = useState(false);

  const [ open, setOpen ] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo( 1, 1 );

    if (!simulacao.data.length) {
      setActiveStep(activeStep - 1);
    }
  }, [simulacao.data, setActiveStep, activeStep]);

  const handleSelect = useCallback(value => {
    setSelected(value);

    const data = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    const status = logged ? 'inside' : 'outside';

    localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify({...data, ...value, status }))
  }, []);

  const handleSubmit = useCallback(async () => {
    simulacao.selectSeguro({ seguro: selected });

    localStorage.removeItem('TDA_Seguros@SimulacaoStorage');
    localStorage.removeItem('TDA_Seguros@SimulacaoValoresDeletados');

    handleNext();
  }, [selected, simulacao, handleNext]);

  return (
    <>
      <Box className={classes.box}>
        <ResultadoSimulacaoTable simulacao={simulacao} onSelect={handleSelect} />
        <Box className={classes.buttonsWrapper}>
          <LoadingButton
            className={classes.button}
            onClick={() => setActiveStep(activeStep - 1)}
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            size="large"
          >
            Voltar para simulação
          </LoadingButton>

          {
            logged ?
              <LoadingButton
                className={classes.button}
                onClick={() => setOpen(true)}
                variant="contained"
                loading={getting}
                color="primary"
                size="large"
              >
                Salvar Simulação
              </LoadingButton>
            : <></>
          }

          <LoadingButton
            className={classes.button}
            onClick={handleSubmit}
            disabled={!selected}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            size="large"
          >
            Continuar
          </LoadingButton>
        </Box>
      </Box>

      <ModalCotacao
        open={ open }
        setOpen={ setOpen }
      />
    </>
  );
}
