import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { DrawerItens } from './DrawerItens'
import styles from './AppDrawer.styles'

export function AppDrawer ({ drawerOpen, closeDrawer }) {
  const [ username, setUsername ] = useState('');

  const classes = styles()

  const drawerPaperClassName = clsx({
    [classes.drawerPaper]: true,
    [classes.drawerPaperClose]: !drawerOpen
  })

  useEffect(() => {
    setTimeout(() => {
      const username = localStorage.getItem('@TDA_usuario');

      setUsername( username );
    }, 1000);
  }, [ localStorage.getItem('@TDA_usuario') ]);

  return (
    <Drawer
      variant='permanent'
      classes={{ paper: drawerPaperClassName }}
      open={drawerOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={closeDrawer} size='small'>
          <ChevronLeftIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />
      <DrawerItens />

      {
        username === 'avalystvrt@versaodeteste.com.br'
          ? <div className={ classes.versionTest }>
              <span className={ classes.textVersionTest }>
                VERSÃO DE TESTE
              </span>
            </div>
          : <></>
      }
    </Drawer>
  )
}
