import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextField } from '~/components';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import { LoadingButton } from '~/components';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {
  incendio,
  eletrico,
  aluguel,
  vendaval,
  civil,
} from '../../utils/constants/tooltipText';

import {
  useHistory,
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  avatar: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    margin: 'auto'
  },

  coberturaHeader: {
    verticalAlign: 'bottom',
    fontSize: 20
  },

  cobertura: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    width: 245,
  },

  radio: {
    display: 'flex',
    flexDirection: 'row',
  },

  button: {
    marginLeft: theme.spacing(1)
  },

  actionsView: {
    display: 'flex',
    flexDirection: 'reverse-row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: 10,
  }
}));

export default function ModalSelectCotacao({ open, setOpen, simulacao }) {
  const classes = useStyles();

  const history = useHistory();

  const [ seguradora, setSeguradora ] = useState();
  const [ premioTotal, setPremioTotal ] = useState(0);

  function handleSelectSeguradora() {
    const seguradoraSelecionando = simulacao.find( item => `${ item.pkSeguradora }` === seguradora );

    const data = {
      ...seguradoraSelecionando,
      dscaminhologo: seguradoraSelecionando.seguradora.dscaminhologo,
      premioLiquido: seguradoraSelecionando.premioliquido,
      premioTotal: seguradoraSelecionando.premiototal,
      coberturasExibir: {
        incendio_raio_explosao: seguradoraSelecionando.incendioraioexplosao,
        danos_eletricos: seguradoraSelecionando.danoseletricos,
        perda_pagamento_aluguel: seguradoraSelecionando.perdapagamentoaluguel,
        responsabilidade_civil: seguradoraSelecionando.responsabilidadecivil,
        quebra_vidros: seguradoraSelecionando.vendavalGranizo,
      }
    };

    const simulacaoStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify({
      ...data,
      ...simulacaoStorage,
    }));

    history.push('/app/seguro-incendio');
  };

  useEffect(() => {
    simulacao.map( dado => {
      const simulacao = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      simulacao.qtdParcelas = dado.premioTotalParcelas[premioTotal]?.number;

      localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify( simulacao ));
    });
  }, [ premioTotal ]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen( false )}
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6">
            SEGURADORAS
          </Typography>
        </Toolbar>

        <div style={{ padding: 10 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.coberturaHeader}>
                  Coberturas
                </TableCell>

                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      <img
                        className={classes.avatar}
                        src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${coberturas.seguradora.dscaminhologo}`}
                        alt={coberturas.seguradora.nome}
                      />
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell className={ classes.cobertura }>
                  Incêndio / Raio / Explosão

                  <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ incendio }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.incendioraioexplosao )}
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell className={ classes.cobertura }>
                  Perda/Pagamento Aluguel

                  <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ aluguel }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.perdapagamentoaluguel )}
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell className={ classes.cobertura }>
                  Responsabilidade Civil

                  <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ civil }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.responsabilidadecivil )}
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell className={ classes.cobertura }>
                  Vendaval / Granizo

                  <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ vendaval }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.vendavalGranizo )}
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell className={ classes.cobertura }>
                  Danos Elétricos

                  <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ eletrico }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell align="center" key={coberturas.pkSeguradora}>
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.danoseletricos )}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell variant="head" align="left">
                  PRÊMIO LÍQUIDO
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell variant="head" key={coberturas.pkSeguradora}align="center">
                      { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( coberturas.premioliquido )}
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell variant="head" align="left">
                  PRÊMIO TOTAL
                </TableCell>
                {
                  simulacao.map(coberturas => (
                    <TableCell variant="head"  key={coberturas.pkSeguradora}align="center">
                      <TextField
                        value={coberturas.premioTotalParcelas[premioTotal]?.number}
                        name="qtdParcelas"
                        onChange={e => {
                          let i = coberturas.premioTotalParcelas.findIndex(( item, index ) => {
                            if( item.number === e.target.value ) {
                              return true
                            }

                            return false
                          })
                          setPremioTotal( i );
                        }}
                        select
                      >
                        {coberturas.premioTotalParcelas.map(parcela => (
                          <MenuItem key={parcela.value} value={parcela.number}>
                            {parcela.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  ))
                }
              </TableRow>

              <TableRow>
                <TableCell variant="head">SELECIONAR SEGURADORA</TableCell>

                {
                  simulacao.map( item => (
                    <TableCell key={ item.pkSeguradora } align="center">
                      <Radio
                        checked={ seguradora === `${ item.pkSeguradora }` }
                        onChange={ event => {
                          setSeguradora( event.target.value )
                        }}
                        value={ item.pkSeguradora }
                        name="seguradoraRadio"
                        inputProps={{ 'aria-label': 'selecionar-seguradora' }}
                      />
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableFooter>
          </Table>

          <div className={ classes.actionsView }>
            <LoadingButton
              className={classes.button}
              onClick={() => setOpen(false)}
              color="primary"
            >
              Cancelar
            </LoadingButton>

            <LoadingButton
              className={classes.button}
              onClick={ handleSelectSeguradora }
              disabled={ !seguradora }
              variant="contained"
              color="primary"
            >
              Contratar
            </LoadingButton>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

// function ResultadoSimulacaoTableFooter({ seguradora }) {


//   return (

//   );
// }
