import { useState, useCallback, useMemo } from 'react';

import api from '~/services/api';

export default function useContacts({ checkApiCall }) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const getUsers = useCallback(async () => {
    setFetching(true);

    try {
      const { data: usersData } = await checkApiCall(api.getUsers);

      setData(usersData);

      return { success: true };
    } catch {
      return { success: false };
    } finally {
      setFetching(false);
    }
  }, [checkApiCall]);

  const addUser = useCallback(
    async userData => {
      try {
        const { data: newUser } = await checkApiCall(api.addUser, [userData]);

        setData(oldUsers => [...oldUsers, newUser]);

        return { success: true };
      } catch {
        return { success: false };
      }
    },
    [checkApiCall]
  );

  return useMemo(
    () => ({
      data,
      fetching,
      getUsers,
      addUser
    }),
    [data, fetching, getUsers, addUser]
  );
}
