import React, { useState, useEffect } from 'react';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import CloseIcon from '@material-ui/icons/Close';

import {
  PDFViewer
} from '@react-pdf/renderer';

import MyDocumentMensal from '../../utils/template/relatorioMensal';
import MyDocumentDiario from '../../utils/template/relatorioDiario';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },

  fab: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: theme.spacing(15),
    right: theme.spacing(3),
  },

  popover: {
    pointerEvents: 'none',
  },

  paper: {
    padding: theme.spacing(1),
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },

  appBar: {
    width: '100%',
  },

  tab: {
    width: '100%',
  },
}));

export default function ModalReports({ open: openModal, setOpen, filtros, propostas }) {
  const classes = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={() => setOpen( false )}
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="usuarios">
            Relatório { filtros.type }
          </Typography>
        </Toolbar>

        <PDFViewer
          width="100%"
          height="100%"
        >
          { filtros.type === 'Mensal' ?
            <MyDocumentMensal filtros={ filtros } propostas={ propostas } /> :
            <MyDocumentDiario filtros={ filtros } propostas={ propostas } />
          }
        </PDFViewer>

        <Fab
          className={ classes.fab }
          color="secondary"
          onClick={() => setOpen(false)}
        >
          <CloseIcon style={{ color: '#FFF', fontSize: 40}}/>
        </Fab>
      </Paper>
    </Modal>
  );
};
