export default [
  {
    title: 'Limpeza de Estofados',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/limpeza-de-sofa-e-estofados/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/01/11104820/Limpeza-de-Sof%C3%A1-e-Estofados_P-1-2-1.png',
  },

  {
    title: 'Eletricista e Encanador',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/eletricista-e-encanador/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/02/11110315/Instala%C3%A7%C3%A3o-e-Conserto-de-Chuveiro-ou-Ducha_P.png',
  },

  {
    title: 'Fechadura Digital',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/instalacao-venda-de-fechadura-digital/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/09/11104831/Instala%C3%A7%C3%A3o-de-Fechadura-Digital-718-1-4-1.png',
  },

  {
    title: 'Porteiro Eletronico',
    url: 'https://www.portosegurofaz.com.br/servicos/porteiro-eletronico/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-porteiro-eletronico.png',
  },

  {
    title: 'Desentupimento',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/desentupimentos-e-caca-vazamentos/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/03/11110359/Desentupimento-em-Geral_P.png',
  },

  {
    title: 'Ar Condicionado',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/limpeza-de-ar-condicionado/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/01/11110641/Conserto-de-Ar-Condicionado_P.png',
  },

  {
    title: 'Fixação Kit de Utensilios',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/fixacao-de-kits-e-utensilios/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/03/11110309/Fixa%C3%A7%C3%A3o-Utens%C3%ADlios-de-Cozinha_P.png',
  },

  {
    title: 'Instalação de TV',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/instalacao-de-tv/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/01/11104823/Instala%C3%A7%C3%A3o-de-Suporte-de-TV_P-2-1.png',
  },

  {
    title: 'Instalação de Coifa',
    url: 'https://www.portosegurofaz.com.br/servicos/instalacao-de-coifa/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-coifa.png',
  },

  {
    title: 'Instalação de Rede de Proteção',
    url: 'https://www.portosegurofaz.com.br/rede-de-protecao/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-rede-de-protecao.png',
  },

  {
    title: "Caixa D'Agua",
    url: 'https://www.portosegurofaz.com.br/servicos/limpeza-de-caixa-dagua/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/02/11110219/Limpeza-de-Caixa-d%E2%80%99%C3%A1gua_P.png',
  },

  {
    title: 'Gincho',
    url: 'https://www.portosegurofaz.com.br/servicos/guincho/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-guincho.png',
  },

  {
    title: 'Pintura Express',
    url: 'https://www.portosegurofaz.com.br/pintura-express/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-pintura-express.png',
  },

  {
    title: 'Tela Mosquiteira',
    url: 'https://www.portosegurofaz.com.br/tela-mosquiteira/',
    image: 'https://www.portosegurofaz.com.br/wp-content/themes/portosegurofaz/assets/icons/icon-tela-mosquiteira.png',
  },

  {
    title: 'Montagem Moveis',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/marcenaria/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/05/11104440/Alinhamento-de-Portas.png',
  },

  {
    title: 'Chaveiro',
    url: 'https://www.portosegurofaz.com.br/c/servicos-casa/chaveiro-residencial/',
    image: 'https://s3-sa-east-1.amazonaws.com/cdn.portosegurofaz.com.br/wp-content/uploads/2017/03/11110452/C%C3%B3pia-ou-Confec%C3%A7%C3%A3o-de-Chave_P.png',
  },
];
