export const statusOptions = [
  {
    label: 'Ativo',
    value: 'Ativo'
  },
  {
    label: 'Inativo',
    value: 'Inativo'
  }
];
