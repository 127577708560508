import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { LoadingButton } from '~/components';
import { useStore } from '~/store';
import { SIGNUP_INITIAL, signupSchema } from '~/utils/constants';
import { delay } from '~/utils/helpers';

import useStyles from './styles';
import { SignupForm } from './SignupForm';

export function Cadastro({ activeStep, setActiveStep }) {
  const { alert, auth } = useStore();
  const [sending, setSending] = useState(false);

  const history = useHistory();

  const classes = useStyles();

  const handleLogin = () => {
    history.push('/app/dashboard');
  };

  const handleSubmit = useCallback(
    async signUpData => {
      setSending(true);

      try {
        // await delay();
        const { success, error } = await auth.signUp({ signUpData });
        setSending(false);

        window.scrollTo(1,1);

        if (success) {
          alert.addToast({
            type: 'success',
            message: 'Cadastro realizado com sucesso!'
          });

          handleLogin();
        } else {
          alert.addToast({ message: error.response.data.errors[0].msg });
        }
      } catch( error ) {
        setSending(false);
        alert.addToast({ message: 'Erro inesperado ao se cadastrar' });
      }
    },
    [alert, auth, history]
  );

  useEffect(() => {
    window.scrollTo(1,1);
  }, []);

  return (
    <Box className={classes.box}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={SIGNUP_INITIAL}
        validationSchema={signupSchema}
        validateOnMount
      >
        {formik => {
          return (
            <>
              <SignupForm formik={formik} />
              <Box className={classes.buttonsWrapper}>
                <LoadingButton
                  className={classes.button}
                  onClick={() => setActiveStep(activeStep - 1)}
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  size="large"
                >
                  Voltar
                </LoadingButton>
                <LoadingButton
                  className={classes.button}
                  type="submit"
                  form="signup_form"
                  loading={sending}
                  disabled={!formik.isValid}
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  size="large"
                >
                  Continuar
                </LoadingButton>
              </Box>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
