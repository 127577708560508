import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import Title from './Title';

import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import api from '~/services/api';

import { useStore } from '~/store';

import ModalProposta from '~/components/ModalProposta';

export default function Orders({ loadingOrders, propostas }) {
  const [ proposta, setProposta ] = useState( null );

  const [ open, setOpen ] = useState(false);
  const [ getting, setGetting ] = useState({ load: false, id: 0 });

  const { alert } = useStore();

  async function handleSelectProposta( id ) {
    setGetting({ load: true, id })
    try {
      const response = await api.getProposta( id );

      if( response.statusText === 'OK') {
        setGetting( false );

        setProposta( response.data );

        setOpen( true );
      } else {
        setGetting( false );

        alert.addToast({ message: 'Erro ao buscar proposta' });
      }
    } catch ( error ) {
      setGetting( false );

      alert.addToast({ message: 'Erro ao buscar proposta' });
    }
  };

  function setColorStatus( value ) {
    switch( value ) {
      case 'PAGA':
        return '#12C100';
      case 'PENDENTE':
        return '#F2EB00'
      case 'PARCELADA':
        return '#00CDF2'
      case 'CANCELADA':
        return '#F20000'
      default:
        return '#ECECEC'
    }
  };

  return (
    <>
      <Title>Propostas</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Protocolo</TableCell>
            <TableCell>Usuário</TableCell>
            <TableCell>Proprietário</TableCell>
            <TableCell>Inquilino</TableCell>
            <TableCell>Seguradora</TableCell>
            <TableCell style={{ width: 10 }}>Pagamento</TableCell>
            <TableCell align="left" style={{ width: 100 }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            !loadingOrders ?
              propostas.length === 0 ? (
                  <>
                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell></TableCell>
                  </>
                )
              : (
                  propostas.map(row => (
                    <TableRow key={row.pkProposta}>
                    <TableCell>{row.pkProposta}</TableCell>
                    <TableCell>{row.nomeUsuario}</TableCell>
                    <TableCell>{row.proprietarioNome}</TableCell>
                    <TableCell>{row.inquilinoNome}</TableCell>
                    <TableCell>{row.nomeSeguradora}</TableCell>

                    <TableCell align="justify" >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FiberManualRecordIcon style={{ color: setColorStatus( row.situacaoPagamento )}}/>

                        <span style={{ fontSize: 14, fontWeight: 'normal' }}>{ row.situacaoPagamento }</span>
                      </div>
                    </TableCell>

                    <TableCell align="left" style={{ width: 130 }}>
                      <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>Visualizar proposta</span>} placement="right">
                        <IconButton
                          onClick={() => handleSelectProposta( row.pkProposta )}
                          color="primary"
                        >
                          { getting.load && getting.id === row.pkProposta ? <CircularProgress size={ 20 } /> : <VisibilityIcon /> }
                        </IconButton>
                      </Tooltip>

                      {
                        row.arquivoSeguradora
                          ? <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>Download da Apólice</span>} placement="right">
                              <IconButton
                                onClick={() => window.open(`${ process.env.REACT_APP_BASE_API_URL }/public/images/upload/formularios/${ row.arquivoSeguradora }`)}
                                color="primary"
                              >
                                <CloudDownloadIcon />
                              </IconButton>
                            </Tooltip>
                          : <></>
                      }
                    </TableCell>
                  </TableRow>
                ))
              )
            :
            <>
              <TableRow>
                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>
              </TableRow>

              <TableRow>
                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>
              </TableRow>

              <TableRow>
                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>

                <TableCell><Skeleton animation="wave" /></TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>

      <ModalProposta data={ proposta } setOpen={ setOpen } open={ open } />
    </>
  );
}
