import React, { useEffect, useState, useCallback } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  transformUsers,
  getColumns,
  getComparator,
  stableSort
} from '~/utils/helpers';
import { usersDataTable } from '~/utils/constants';
import { useStore } from '~/store';

import useStyles from './styles';
import { UsersTableHead } from './UsersTableHead';
import { UsersTableToolbar } from './UsersTableToolbar';

import { AddUserModal } from '../AddUser';

export function UsersTable() {
  const { users, alert } = useStore();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const transformedUsers = transformUsers({ data: users.data });

  const columns = getColumns(usersDataTable, { sm, md });

  const classes = useStyles({ md, sm });

  const getUsers = useCallback(async () => {
    const { success } = await users.getUsers();

    if (!success) {
      alert.addToast({ message: 'Erro ao buscar usuários' });
    }
  }, [ users ]);

  useEffect(() => {
    getUsers();
  }, []);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nome');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [addUserOpen, setAddUserOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = user => {
    setSelectedUser(user);

    setAddUserOpen(true);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, transformedUsers.length - page * rowsPerPage);

  return (
    <>
      <Paper className={classes.paper}>
        <UsersTableToolbar getUsers={getUsers} />
        <TableContainer className={classes.table}>
          <Table
            aria-labelledby="tabelaUsuários"
            aria-label="tabela usuarios"
            size={md ? undefined : 'small'}
          >
            <UsersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              cells={columns}
            />
            <TableBody>
              {users.fetching && (
                <TableRow className={classes.hiddenRow}>
                  <TableCell>
                    <Paper elevation={4} className={classes.progressContainer}>
                      <CircularProgress
                        size={30}
                        color="primary"
                        thickness={6}
                      />
                    </Paper>
                  </TableCell>
                </TableRow>
              )}
              {stableSort(transformedUsers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(user => {
                  return (
                    <TableRow key={user.id}>
                      {columns.map(column => (
                        <TableCell key={column.id}>{user[column.id]}</TableCell>
                      ))}

                      <TableCell align="right">
                        <Box className={classes.acoes}>
                          <IconButton
                            onClick={() => handleEdit(user)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }} />}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            toolbar: classes.toolbar,
            selectRoot: classes.selectRoot,
            actions: classes.actions
          }}
          labelRowsPerPage="Resultados por página"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={transformedUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <AddUserModal
        selectedUser={selectedUser}
        open={addUserOpen}
        close={() => setAddUserOpen(false)}
        getUsers={getUsers}
      />
    </>
  );
}
