import { object, string, bool } from 'yup';

export const TOKEN_KEY = '@TDA_token';

export const LOGIN_INITIAL = {
  email: '',
  password: '',
  remember: false
};

export const loginSchema = object().shape({
  email: string()
    .typeError('Email deve ter formato texto')
    .required('Preencha seu e-mail de login')
    .email('Deve ser um e-mail válido'),
  password: string()
    .typeError('Senha deve ter formato texto')
    .required('Preencha sua senha'),
  remember: bool()
});
