export function getColumns(
  data,
  { sm = false, md = false, smIndexes = [0, 1, 2], indexes = [0] }
) {
  if (md) {
    return data;
  }

  if (sm) {
    return data.filter((_, index) => smIndexes.includes(index));
  }

  return data.filter((_, index) => indexes.includes(index));
}
