import { makeStyles } from '@material-ui/core/styles'

const Styles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

export default Styles
