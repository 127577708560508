import { useState, useCallback } from 'react';

import api from '~/services/api';

export default function useContacts({ alert, checkApiCall }) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const getContacts = useCallback(async () => {
    setFetching(true);

    try {
      const { data: contactsData } = await checkApiCall(api.getContacts);

      setData(contactsData);

      return { success: true };
    } catch {
      alert.addToast({ message: 'Erro ao buscar os contatos' });
      return { success: false };
    } finally {
      setFetching(false);
    }
  }, [checkApiCall, alert]);

  const addContact = async contactData => {
    try {
      const { data: newContact } = await api.addContact({
        ...contactData,
        tpCargo: contactData.tpContato.toString()
      });

      setData(oldContacts => [...oldContacts, newContact]);

      alert.addToast({
        type: 'success',
        message: 'Contato adicionado com sucesso!'
      });
      return { success: true };
    } catch(err) {
      alert.addToast({ message: 'Erro ao adicionar contato' });
      return { success: false };
    }
  };

  return {
    data,
    fetching,
    getContacts,
    addContact
  };
}
