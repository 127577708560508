import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import { Alert } from '~/components/Alert';

import { StateProvider } from '~/store';

import Routes from '~/routes';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'light',

    common: {
      black: '#000',
      white: '#FFF',
    },

    primary: {
      main: '#3f51b5'
    },

    secondary: {
      main: '#f44336'
    },

    error: {
      main: '#f44336'
    }
  },
});

const App = () => {
  return (
    <CssBaseline>
      <StateProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <Routes />
            <Alert />
          </ThemeProvider>
        </Router>
      </StateProvider>
    </CssBaseline>
  );
};

export default App;
