import React from 'react';

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import logo from '../../assets/logo.png';

import sompo from './assets/sompo.jpg';
import mapfre from './assets/mapfre.png';
import porto from './assets/porto.jpg';
import liberty from './assets/liberty.jpg';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    paddingTop: 10,
    paddingHorizontal: 25,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: .2,
    borderBottomColor: '#000'
  },

  title: {
    fontSize: 14,
  },

  logo: {
    width: 120,
  },

  table: {
    padding: 5,
    marginTop: 20,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    borderBottom: .2,
    borderBottomColor: '#000',
    marginBottom: 5,
  },

  rowSeguradora: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    height: 70,
    borderBottom: .2,
    borderBottomColor: '#000',
    marginBottom: 5,
  },

  cell: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 5,
    width: '25%',
    padding: 2,
  },

  cellFirstSeguradora: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 5,
    width: '40%',
    padding: 2,
  },

  cellFirst: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 5,
    width: '40%',
    padding: 2,
  },

  cellLg: {
    display: 'flex',
    alignItems: 'left',
    marginBottom: 5,
    width: '75%',
    padding: 2,
  },

  cellCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    width: '75%',
    padding: 2,
  },

  headerText: {
    fontSize: 12,
  },

  text: {
    fontSize: 10,
  },

  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 15,
  },

  subTitleText: {
    fontSize: 14,
  },

  logoSeguradora: {
    width: 80,
    height: 80,
  },

  nomeSeguradora: {
    fontSize: 10,
  },
});

export default function DocumentPDF({ data }) {
  function getLogoSeguradora( seguradora ) {
    switch( seguradora ) {
      case 'SOMPO':
        return sompo;
      case 'MAPFRE':
        return mapfre;
      case 'PORTO SEGURO':
        return porto;
      case 'LIBERTY SEGUROS':
        return liberty;
    }
  };

  return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header }>
            <Image src={ logo } style={ styles.logo } />

            <Text style={ styles.title }>COTAÇÃO DE SEGURO INCÊNDIO</Text>

            <View style={{ width: 100 }}/>
          </View>

          <View style={ styles.table }>
            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Cotação Número</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Data Cotação</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Validade</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Vigência</Text>
              </View>
            </View>

            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.text }>{ data.numeroCotacao }</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.text }>{ data.dataCotacao }</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.text }>{ data.cotacaoValidade }</Text>
              </View>

              <View style={ styles.cell }>
                <Text style={ styles.text }>{ data.cotacaoVigencia }</Text>
              </View>
            </View>
          </View>

          <View style={ styles.subTitle }>
            <Text style={ styles.subTitleText }>DADOS DO PROPONENTE</Text>
          </View>

          <View style={ styles.table }>
            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Nome:</Text>
              </View>

              <View style={ styles.cellLg }>
                <Text style={ styles.text }>{ data.nomeInteressado }</Text>
              </View>
            </View>

            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Email:</Text>
              </View>

              <View style={ styles.cellLg }>
                <Text style={ styles.text }>{ data.emailInteressado }</Text>
              </View>
            </View>
          </View>

          <View style={ styles.subTitle }>
            <Text style={ styles.subTitleText }>DADOS DO SEGURO</Text>
          </View>

          <View style={ styles.table }>
            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Tipo do Imóvel:</Text>
              </View>

              <View style={ styles.cellLg }>
                <Text style={ styles.text }>{ data.tipoImovel }</Text>
              </View>
            </View>

            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Ocupação:</Text>
              </View>

              <View style={ styles.cellLg }>
                <Text style={ styles.text }>{ data.tipoOcupacao }</Text>
              </View>
            </View>

            <View style={ styles.row }>
              <View style={ styles.cell }>
                <Text style={ styles.headerText }>Assistência 24h:</Text>
              </View>

              <View style={ styles.cellLg }>
                <Text style={ styles.text }>{ data.assisstencia24H }</Text>
              </View>
            </View>
          </View>

          <View style={ styles.subTitle }>
            <Text style={ styles.subTitleText }>SEGURADORAS DISPONÍVEIS</Text>
          </View>

          <View style={ styles.table }>
            <View style={ styles.rowSeguradora }>
              <View style={ styles.cellFirstSeguradora }>
                <Text style={ styles.headerText }>Seguradoras:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Image styles={ styles.logoSeguradora } source={() => getLogoSeguradora( item.nome )} />
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>INCÊNDIO/RAIO/EXPLOSÃO:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.incendio )}</Text>
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>PERDA/PAGAMENTO ALUGUEL:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.perda )}</Text>
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>RESPONSABILIDADE CIVIL:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.civil )}</Text>
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>VENDAVAL/GRANIZO:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.granizo )}</Text>
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>DANOS ELÉTRICOS:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.danos )}</Text>
                  </View>
                ))
              }
            </View>

            <View style={ styles.row }>
              <View style={ styles.cellFirst }>
                <Text style={ styles.text }>PRÊMIO TOTAL:</Text>
              </View>

              {
                data.seguradoras.map(item => (
                  <View style={ styles.cell } key={ item.nome }>
                    <Text style={ styles.text }>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( item.total )}</Text>
                  </View>
                ))
              }
            </View>
          </View>

          <Text style={ styles.text }>*Esta cotação tem validade de { data.dias } dias corridos após a data de emissão</Text>
          <Text style={ styles.text }>*Data Impressão: { data.emissao }</Text>
        </Page>
      </Document>
  );
};
