import { coberturas } from '~/utils/constants';
import { toCurrency } from '~/utils/helpers/currency';

const gerarParcelamento = ({ valorTotal, maxParcelas }) => {
  let parcelas = [];

  if (!valorTotal) return parcelas;

  for (let i = 1; i <= maxParcelas; i++) {
    const value = valorTotal / i;
    const valueArray = value.toString().split('.');
    const label = `${i}X ${`R$ ${ valueArray[0]},${ valueArray[1].substr(0, 2)}`}`;
    const number = i;

    parcelas = [...parcelas, { value, label, number }];
  }
  return parcelas;
};

const getValorCobertura = ({ cobertura, dado }) => {
  switch (cobertura.value) {
    case 'incendio_raio_explosao':
      return dado.coberturaIncendioRaioExplosao;
    case 'perda_pagamento_aluguel':
      return dado.coberturaPerdaPagamentoAluguel;
    case 'responsabilidade_civil':
      return dado.coberturaResponsabilidadeCivil;
    case 'quebra_vidros':
      return dado.coberturaQuebraVidros;
    case 'danos_eletricos':
      return dado.coberturaDanosEletricos;
    default:
      return 0;
  }
};

export const transformSimulacao = ({ data }) =>
  data.map(dado => {
    return {
      ...dado,
      id: `${dado.seguradora.pkSeguradoras}`,
      coberturasExibir: coberturas.reduce(
        (acc, cobertura) => ({
          ...acc,
          [cobertura.value]: getValorCobertura({ cobertura, dado })
        }),
        {}
      ),
      seguradora: {
        ...dado.seguradora,
        id: dado.seguradora.pkSeguradoras,
        nome: dado.seguradora.nome,
        imagem: `${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${dado.seguradora.dscaminhologo}`
      },
      premioLiquido: dado.valorPremioLiquidoTotal,
      premioTotal: dado.valorPremioTotal,
      premioTotalParcelas: gerarParcelamento({
        valorTotal: dado.valorPremioTotal,
        maxParcelas: 4
      })
    };
  });
