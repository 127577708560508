import React from 'react';

export const incendio = "1) Incêndio de Qualquer Natureza: Garante os danos materiais decorrentes do surgimento de chamas em situação de combustão. / 2) Queda de Raio: Garante os danos materiais em função da descarga atmosférica que atingir o interior do imóvel segurado. / 3) Explosão de Qualquer Natureza: Garante os danos materiais decorrentes da explosão originada pelo escape de gases ou vapores sob grande pressão."

export const eletrico = "Danos Elétricos – Curto Circuito: Garante as perdas e danos materiais causados a máquinas, equipamentos ou instalações elétricas do imóvel segurado por consequência de variações de tensão, curto-circuito, descargas elétricas, entre outros fenômenos de natureza elétrica."

export const aluguel = "Perda/Pagamento de Aluguel: Garante a indenização exclusivamente para as despesas de aluguel e encargos, caso o imóvel não possa ser ocupado no todo ou parte, em função da ocorrência dos eventos previstos na Cobertura de Incêndio/Raio/Explosão."

export const vendaval = "Vendaval/Granizo: Garante o pagamento por perdas e danos materiais ocorridos ao imóvel por consequência de vendaval, furacão, ciclone, tornado, granizo, bem como pela queda de aeronaves, além de impacto de veículos terrestres e fumaça."

export const civil = "Responsabilidade Civil: Garante o reembolso ao Segurado das quantias pelas quais vier a ser civilmente responsabilizado e condenado a pagar (conforme limite da apólice), em decorrência de sentença judicial transitada em julgado ou por acordo entre as partes, com anuência prévia e expressa da Seguradora, relativas a reclamações por danos corporais e/ou materiais involuntariamente causados a terceiros, conforme Condições Gerais do seguro."
