import React, { useState } from 'react';

import ModalProposta from '../../components/ModalProposta';

import { PropostaForm } from './PropostaForm';
import { PropostasTable } from './PropostasTable';
import { useStore } from '~/store';

export function Reports() {
  const { propostas } = useStore();
  const [ data, setData ] = useState( null );

  const [ open, setOpen ] = useState(false);

  function handleOpenModal( data ) {
    setData( data );

    setOpen( true );
  };

  return (
    <>
      <PropostaForm />
      {!!propostas.data.length && <PropostasTable handleOpenModal={ handleOpenModal } />}

      <ModalProposta data={ data } setOpen={ setOpen } open={ open } />
    </>
  );
}
