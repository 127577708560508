/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';

import { phoneMask } from '~/utils/helpers/masks';
import { TextFieldMask } from '~/components/Inputs';

export function FormikMaskedValue(props) {
  const [field, meta] = useField(props);

  return (
    <TextFieldMask
      error={meta.touched ? !!meta.error : undefined}
      helperText={meta.touched ? meta.error : undefined}
      mask={phoneMask}
      value={field.value}
      name={field.name}
      onBlur={field.onBlur}
      {...props}
      onChange={field.onChange}
    />
  );
}
