import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/Settings';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AllInboxIcon from '@material-ui/icons/AllInbox';

import {
  Dashboard,
  MinhaImobiliaria,
  Users,
  Reports,
  IncendioStepper,
  ServicoResidencial,
  Propostas,
  Cotacoes,
} from '~/pages';

import { SwitchRoutes } from './SwitchRoutes';

export const userRoutes = [
  {
    label: 'Usuários',
    path: '/app/admin/usuarios',
    exact: true,
    Component: Users,
    Icon: PeopleIcon
  }
];

export const adminRoutes = [
  {
    label: 'Usuários',
    path: '/app/admin/usuarios',
    Component: () => SwitchRoutes(userRoutes),
    Icon: PeopleIcon
  },
  {
    label: 'Minha Imobiliária',
    path: '/app/admin/minha-imobiliaria',
    exact: true,
    Component: MinhaImobiliaria,
    Icon: HomeWorkIcon
  }
];

export const appRoutes = [
  {
    label: 'Dashboard',
    path: '/app/dashboard',
    exact: true,
    Component: Dashboard,
    Icon: DashboardIcon
  },
  {
    label: 'Seguro Incêndio',
    path: '/app/seguro-incendio',
    Component: IncendioStepper,
    Icon: FireplaceIcon
  },
  {
    label: 'Administração',
    path: '/app/admin',
    Component: () => SwitchRoutes(adminRoutes),
    Icon: SettingsIcon,
    nested: adminRoutes
  },
  {
    label: 'Relatórios',
    path: '/app/relatorios',
    exact: true,
    Component: Reports,
    Icon: ReceiptIcon
  },
  {
    label: 'Propostas',
    path: '/app/propostas',
    Component: Propostas,
    Icon: AllInboxIcon
  },
  {
    label: 'Cotações',
    path: '/app/cotacoes',
    Component: Cotacoes,
    Icon: AssignmentIcon
  },
  {
    label: 'Serviços Residênciais',
    path: '/app/servicos-residencia',
    Component: ServicoResidencial,
    Icon: BuildIcon
  },
  {
    label: 'Fale Conosco',
    path: '/app/modal-contato',
    Component: null,
    Icon: QuestionAnswerIcon
  }
];

export default () => SwitchRoutes(appRoutes);
