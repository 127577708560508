import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  price: {
    fontSize: theme.typography.fontSize * 1.6,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  },
  avatarCell: {
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 1.4
  },
  avatar: {
    width: 100,
    height: 100,
    margin: 'auto'
  },
  chip: {
    margin: theme.spacing(0.3)
  },
  header: {
    background: theme.palette.primary.main
  },
  coberturaHeader: {
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    background: theme.palette.primary.main,
    fontSize: 20,
    color: theme.palette.primary.contrastText
  },
  cobertura: {
    whiteSpace: 'nowrap',
    fontSize: theme.typography.fontSize * 1.5,
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  input: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
  coberturas: {
    whiteSpace: 'pre-wrap',
  },
  message: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 1.4,
    textAlign: 'center'
  },
  data: {
    maxHeight: 100,
    height: '80px',
    verticalAlign: 'top',
    '&:nth-child(even)': {
      // background: theme.palette.grey[100]
    },
    '&:nth-child(odd)': {
      // background: theme.palette.grey[200]
    }
  }
}));

export default Styles;
