import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 310,
    maxHeight: '70vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    overflow: 'hidden',
    outline: 'none'
  },
  title: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.fontSize * 1.6
  },
  content: {
    padding: theme.spacing(2.5)
  },
  message: {
    marginTop: theme.spacing(1.8),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  buttons: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  button: {
    flex: 1,
    maxWidth: 300,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    }
  }
}));

export default Styles;
