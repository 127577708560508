import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    overflow: 'hidden',
    zIndex: theme.zIndex.snackbar
  },
  toast: {
    margin: theme.spacing(1),
    minWidth: theme.spacing(35)
  }
}));

export default Styles;
