import React from 'react';
import { useField } from 'formik';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export function FormikSwitch({ name, label, className, ...rest }) {
  const [field, , helpers] = useField(name);

  function handleChange(e) {
    helpers.setValue(e.target.checked);
  }

  return (
    <FormControlLabel
      className={className}
      label={label}
      control={
        <Switch
          color="primary"
          {...field}
          {...rest}
          checked={field.value}
          onChange={handleChange}
        />
      }
    />
  );
}
