import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  switch: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1)
  }
}));

export default Styles;
