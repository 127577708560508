import { useState, useCallback, useMemo } from 'react';

import api from '~/services/api';

export default function useContacts() {
  const [data, setData] = useState([]);

  const getPropostas = useCallback(
    async ({ seguradora, vigenciaInicio, vigenciaFim, tipo, status, deletado }) => {
      try {
        const { data: propostasData } = await api.getPropostas({
          seguradora,
          vigenciaInicio,
          vigenciaFim,
          status,
          tipo,
          deletado,
        });

        setData(propostasData);

        return { success: true };
      } catch {
        return { success: false };
      }
    },
    []
  );

  const getPropostasIntervalo = useCallback(
    async ({ dataInicio, dataFim, acao }) => {
      try {
        const { data } = await api.getPropostasIntervalo({
          dataInicio,
          dataFim,
          acao,
        });

        setData(data);

        return { data, success: true };
      } catch {
        return { success: false };
      }
    },
    []
  );

  const clearPropostas = useCallback(
    async () => {
      setData([]);
    }
  )

  return useMemo(
    () => ({
      data,
      getPropostas,
      getPropostasIntervalo,
      clearPropostas,
    }),
    [data, getPropostas, getPropostasIntervalo]
  );
}
