import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginTop: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(0.6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  selectOcupacao: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    width: 430,
  },
  inputMoney: {
    width: 210,
  }
}));

export default Styles;
