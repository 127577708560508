/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';

import { NumberFormatInput } from '~/components/Inputs';

export function FormikCurrency(props) {
  const [field, meta, helpers] = useField(props);

  function handleValueChange({ value }) {
    helpers.setValue(value);
  }

  return (
    <NumberFormatInput
      error={meta.touched ? !!meta.error : undefined}
      helperText={meta.touched ? meta.error : undefined}
      placeholder="R$ 0.000,00"
      value={field.value}
      onBlur={field.onBlur}
      {...props}
      inputProps={{ onValueChange: handleValueChange }}
    />
  );
}
