import { useState, useEffect } from 'react';

import api from '~/services/api';

export default function useBankData({ alert }) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const getBankData = async () => {
    setFetching(true);

    try {
      const { data: bankData } = await api.getBankData();

      setData(bankData);

      return { success: true };
    } catch {
      alert.addToast({ message: 'Erro ao buscar dados bancários' });
      return { success: false };
    } finally {
      setFetching(false);
    }
  };

  const addBankData = async bankData => {
    try {
      const { data: newBankData } = await api.addBankData(bankData);

      setData(oldBankData => [...oldBankData, newBankData]);

      alert.addToast({
        type: 'success',
        message: 'Dados bancários adicionado com sucesso!'
      });
      return { success: true };
    } catch {
      alert.addToast({ message: 'Erro ao adicionar dados bancários' });
      return { success: false };
    }
  };

  useEffect(() => {
    getBankData();
  }, []);

  return {
    data,
    fetching,
    getBankData,
    addBankData
  };
}
