import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { toCurrency } from '~/utils/helpers';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import { LoadingButton } from '~/components';

import Deposits from '~/components/Deposits';
import Orders from '~/components/Orders';
import ModalProposta from '~/components/ModalProposta';

import styles from './styles';

import api from '~/services/api';
import { useStore } from '~/store';

import { startOfMonth, endOfMonth, startOfYear } from 'date-fns';

import {
  useHistory,
} from 'react-router-dom';
import ModalReports from '~/components/ModalReports';

export function Dashboard() {
  const classes = styles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const { simulacao, alert } = useStore();

  const history = useHistory();

  const [ propostas, setPropostas ] = useState([]);
  const [ countPropostas, setCountPropostas ] = useState([]);
  const [ countPropostasMouth, setCountPropostasMouth ] = useState([]);

  const [ loading, setLoading ] = useState(false);
  const [ loadingOrders, setLoadingOrders ] = useState(false);

  const [ getting, setGetting ] = useState(false);
  const [ protocolo, setProtocolo ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ proposta, setProposta ] = useState( null );

  const [ filtros, setFiltros ] = useState({});
  const [ propostasModalReport, setPropostasModalReport ] = useState([]);

  const [ open, setOpen ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModalSave, setOpenModalSave ] = useState(false);

  async function getCountPropostas() {
    setLoading(true);
    setLoadingOrders(true);

    try {
      const today = new Date();

      const response = await api.getPropostasIntervalo({
        acao: 'count',
        dataInicio: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)),
        dataFim: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
      });

      const startMonth = startOfMonth( today );
      const endMonth = endOfMonth( today );

      const responseMouth = await api.getPropostasIntervalo({
        acao: 'count',
        dataInicio: startMonth,
        dataFim: endMonth,
      });

      const responseList = await api.getPropostasIntervalo({
        acao: 'report',
        dataInicio: startMonth,
        dataFim: endMonth,
        limite: 5,
      });

      if( response.statusText === 'OK') {
        setLoading( false );
        setLoadingOrders( false );

        setCountPropostas(response.data);
        setCountPropostasMouth(responseMouth.data);
        setPropostas(responseList.data);
      }
    } catch(error) {
      alert.addToast({ message: 'Erro ao buscar dados das últimas propostas' });
      setLoading( false );
      setLoadingOrders( false );
    }
  };

  function textAssistencia( value ) {
    switch( value ) {
      case '1': return 'Básica';
      case '2': return 'Intermediária';
      case '3': return 'Completa';
      default: return 'Não Contratado';
    }
  };

  async function savePropostaStorage() {
    const simulacaoStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));
    const newProposta = JSON.parse( localStorage.getItem('TDA_Seguros@NewProposta'));

    if( newProposta ) {
      try {
        const dataNewProposta = {
          imovelTipoImovel: simulacaoStorage.tipoImovel,
          imovelTipoOcupacao: simulacaoStorage.tipoOcupacao,
          imovelCodigoAtividade: simulacaoStorage.tipoAtividade,
          valorAluguel: simulacaoStorage.valorAluguel,
          assistencia24H: simulacaoStorage.assistencia24,
          assistencia24HText: textAssistencia( simulacaoStorage.assistencia24 ),
          seguradora: simulacaoStorage.seguradora.id,
          qtdParcelas: simulacaoStorage.qtdParcelas,
          valorSegurado: toCurrency( simulacaoStorage.coberturaIncendioRaioExplosao ),
          valorPagamentoAluguel: toCurrency(
            simulacaoStorage.coberturaPerdaPagamentoAluguel
          ),
          valorCoberturaResponsabilidadeCivil: toCurrency(
            simulacaoStorage.coberturaResponsabilidadeCivil
          ),
          valorCoberturaVendavalGranizo: toCurrency(
            simulacaoStorage.coberturaQuebraVidros
          ),
          valorCoberturaDanosEletricos: toCurrency(
            simulacaoStorage.coberturaDanosEletricos
          ),
          premioLiquido: toCurrency(simulacaoStorage.valorPremioLiquidoTotal),
          premioTotal: toCurrency(simulacaoStorage.valorPremioTotal),
          proprietarioNome: newProposta.proprietarioNome,
          proprietarioTipo: newProposta.proprietarioTipo,
          proprietarioRegistro: newProposta.proprietarioRegistro,
          inquilinoNome: newProposta.inquilinoNome,
          inquilinoTipo: newProposta.inquilinoTipo,
          inquilinoRegistro: newProposta.inquilinoRegistro,
          vigenciaInicio: newProposta.vigenciaInicio.split('T')[0],
          vigenciaFim: newProposta.vigenciaFim.split('T')[0],
          dataProposta: newProposta.dataProposta,
          localCep: newProposta.localCep,
          localEndereco: newProposta.localEndereco,
          localNumero: newProposta.localNumero,
          localComplemento: newProposta.localComplemento,
          localBairro: newProposta.localBairro,
          localCidade: newProposta.localCidade,
          localEstado: newProposta.localEstado,
          gerarBoleto: newProposta.gerarBoleto,
          diasAteVencimentoBoleto: 5
        }

        const { data, statusText } = await api.enviarPropostaSeguro( dataNewProposta );

        if( statusText === 'OK') {
          data.assistencia24HText = textAssistencia( data.assistencia24H );

          setProposta( data );
          setOpenModalSave( true );

          localStorage.removeItem('TDA_Seguros@NewProposta');
          localStorage.removeItem('TDA_Seguros@Simulacao');

          getCountPropostas();

          alert.addToast({
            type: 'success',
            message: 'Proposta enviada com sucesso!'
          });
        }

      } catch( error ) {
        alert.addToast({
          message: 'Não foi possível confirmar o seguro, tente novamente'
        });
      }
    }
  };

  function handleCloseModalSave() {
    setOpenModalSave( false );

    setOpen( true );
  };

  useEffect(() => {
    const token = localStorage.getItem('@TDA_token');
    if( token ) {
      api.setAuthToken( token );

      savePropostaStorage();
    }
  }, [ propostas ]);

  useEffect(() => {
    getCountPropostas();
  }, []);

  async function handleGetProposta() {
    if( protocolo !== '') {
      setGetting( true );

      try {
        const response = await api.getProposta( protocolo );

        if( response.statusText === 'OK') {
          setGetting( false );

          setProposta( response.data );
          setProtocolo('');
          setOpen( true );
        } else {
          setGetting( false );

          alert.addToast({ message: 'Erro ao buscar proposta' });
        }
      } catch ( error ) {
        setGetting( false );

        alert.addToast({ message: 'Erro ao buscar proposta' });
      }
    } else {
      alert.addToast({ message: 'Informe número de protocolo' });
    }
  };

  async function handleGetPropostasReport( type ) {
    const today = new Date();

    if( type === 'Mensal') {
      const startMonth = startOfMonth( today );
      const endMonth = endOfMonth( today );

      const response = await api.getPropostasIntervalo({
        acao: 'report',
        dataInicio: startMonth,
        dataFim: endMonth,
      });

      setPropostasModalReport( response.data );
      setFiltros({
        type,
        start_date: startMonth,
        end_date: endMonth,
      });

      setOpenModal( true );
    } else {
      if( type === 'Diário') {
        const response = await api.getPropostasIntervalo({
          acao: 'report',
          dataInicio: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)),
          dataFim: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
        });

        setPropostasModalReport( response.data );
        setFiltros({
          type,
          start_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 3, 0, 0)),
        });

        setOpenModal( true );
      }
    }
  };

  async function searchPropostas() {
    if( search !== '') {
      setGetting( true );
      setLoadingOrders( true );

      try {
        const response = await api.searchPropostas( search || '(*)' );

        if( response.statusText === 'OK') {
          setGetting( false );
          setLoadingOrders( false );

          setPropostas( response.data );
        } else {
          setGetting( false );
          setLoadingOrders( false );

          alert.addToast({ message: 'Erro ao buscar propostas' });
        }
      } catch ( error ) {
        setGetting( false );
        setLoadingOrders( false );

        alert.addToast({ message: 'Erro ao buscar propostas' });
      }
    } else {
      alert.addToast({ message: 'Preencha o campo de busca' });
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Paper style={{ padding: 10 }}>
            <Typography component="span" variant="body1" color="primary" gutterBottom>
              Buscar Propostas
            </Typography>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 10,
            }}>
              <TextField
                value={ search }
                onChange={ event => setSearch( event.target.value )}
                size="small"
                label="Busca"
                variant="outlined"
                onKeyPress={ event => {
                  if( event.key === 'Enter' ) {
                    searchPropostas();
                  }
                }}
              />

              <LoadingButton
                color="primary"
                variant="contained"
                size="medium"
                disabled={getting}
                loading={getting}
                onClick={searchPropostas}
              >
                OK
              </LoadingButton>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Paper style={{ padding: 10, position: 'relative', height: 94, backgroundColor: '#72b6f0' }}>
            <Deposits loading={ loading } getReport={() => handleGetPropostasReport('Diário')} title="Propostas no dia" type="dia" countPropostas={ countPropostas }/>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Paper style={{ padding: 10, position: 'relative', height: 94, backgroundColor: '#69d0a4' }}>
            <Deposits loading={ loading } getReport={() => handleGetPropostasReport('Mensal')} title="Propostas no mês" type="mes" countPropostas={ countPropostasMouth }/>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Orders loading={ loading } loadingOrders={ loadingOrders } propostas={ propostas }/>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={openModalSave}
        onClose={() => setOpenModalSave( false )}
        className={classes.modal}
      >
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6">
              Aviso importante!
            </Typography>
          </Toolbar>
          <div className={ classes.bodyModal }>
            <span className={ classes.message }>Em alguns minutos você irá receber a proposta e o boleto em seu e-mail cadastrado.</span>

            <Button
              onClick={ handleCloseModalSave }
              variant="contained"
              color="primary"
              size="large"
            >
              OK
            </Button>
          </div>
        </Paper>
      </Modal>

      <ModalReports open={ openModal } setOpen={ setOpenModal } filtros={ filtros } propostas={ propostasModalReport }/>

      <ModalProposta data={ proposta } setOpen={ setOpen } open={ open } />
    </>
  );
}
