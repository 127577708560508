import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  radioGroup: {
    marginTop: theme.spacing(0.8),
    marginBottom: theme.spacing(0.8)
  },
  rowRadioSm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
  },
  rowRadio: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
  },
}));

export default Styles;
