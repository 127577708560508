import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { useStore } from '~/store';

function PrivateRoute(props) {
  const { auth } = useStore();

  const redirect = useLocation();

  if (auth.mounted && !auth.token) {
    return <Redirect to={{ pathname: '/simulacao-incendio', state: { redirect } }} />;
  }

  return <Route {...props} />;
}

export default PrivateRoute;
