import React from 'react';

import {
  formatCnpjCpf,
  formatarCEP,
} from '~/utils/constants/formatCnpjCpf';

import {
  formataNumeroTelefone,
} from '../constants/formatPhone';

const getContent = (key, value) => {
  switch (key) {
    case 'pathLogo':
      return value ? (
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/imobiliarias/${value}`}
          height="60px"
          alt="logo imobiliária"
        />
      ) : (
        value
      );
    case 'stParcelarPremio':
      return value > 0 ? 'Ativo' : 'Inativo';
    case 'cep':
      return formatarCEP(value);
    case 'stanalisePorto':
      return value > 0 ? 'Ativo' : 'Inativo';
    case 'stsolicitarservico':
      return value > 0 ? 'Ativo' : 'Inativo';
    case 'geraBoleto':
      return value > 0 ? 'Ativo' : 'Inativo';
    case 'vigencia':
      if( value ) {
        const dataArray = value.split('-');
        return `${dataArray[2]}/${dataArray[1]}/${dataArray[0]}`;
      } else {
        return value;
      }
    case 'registro':
      return formatCnpjCpf(value);
    case 'contatoFixo':
      return value ? formataNumeroTelefone(value.slice(0,2), value.slice(2, 11)) : 'Não Informado';
    case 'contatoCelular':
      return value ? formataNumeroTelefone(value.slice(0,2), value.slice(2, 11)) : 'Não Informado';
    default:
      return value;
  }
};

const getLabel = key => {
  switch (key) {
    case 'nome':
      return 'Nome';
    case 'tipo':
      return 'Tipo';
    case 'registro':
      return 'CPF/CNPJ';
    case 'responsavelCreci':
      return 'CRECI';
    // case 'contatoFixo':
    //   return 'Telefone Fixo';
    // case 'contatoCelular':
    //   return 'Telefone Celular';
    case 'cep':
      return 'CEP';
    case 'endereco':
      return 'Endereço';
    case 'cidade':
      return 'Cidade';
    case 'estado':
      return 'Estado';
    case 'vigencia':
      return 'Vigência do Contrato';
    case 'status':
      return 'Status';
    case 'notas':
      return 'Notas';
    case 'stParcelarPremio':
      return 'Parcelar Prêmio';
    case 'geraBoleto':
      return 'Gera Boleto';
    case 'limiteVencimento':
      return 'Limite Vencimento';
    default:
      return '';
  }
};

export const transformImobiliariaData = imobiliaria => {
  if (!imobiliaria) return [];

  return Object.keys(imobiliaria)
    .map(key => {
      return {
        id: key,
        label: getLabel(key),
        content: getContent(key, imobiliaria[key])
      };
    })
    .filter(item => !!item.label);
};
