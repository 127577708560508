import React, { useState, useEffect } from 'react';

import Avatar from '@material-ui/core/Avatar';
import MaterialLink from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';

import { LoadingButton } from '~/components';

import { LoginForm } from './LoginForm';

import useStyles from './Login.styles';

import api from '~/services/api';
import { useStore } from '~/store';

export function Login(props) {
  const classes = useStyles();

  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ email, setEmail ] = useState('');

  const { alert } = useStore();

  function handleClose() {
    setEmail('');
    setOpen( false );
  };

  async function handleSendPass() {
    setLoading(true);

    try {
      const response = await api.sendPass({ usuario: email });

      if( response.statusText === 'OK') {
        alert.addToast({
          type: 'success',
          message: 'Nova senha enviada com sucesso!'
        });
      } else {
        alert.addToast({
          message: 'Falha ao enviar nova senha!'
        });
      }
    } catch (error) {
      alert.addToast({
        message: 'Falha ao enviar nova senha!'
      });
    } finally {
      setEmail('');
      setLoading(false);
      setOpen( false );
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <LoginForm className={classes.form} />

          <MaterialLink style={{ cursor: 'pointer'}} onClick={() => setOpen( true )} variant="body2">
            Esqueci minha senha
          </MaterialLink>
        </Paper>
      </Container>

      <Modal
        className={ classes.modal }
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
        <div className={ classes.paperModal }>
          <Typography variant="body1">
            Enviaremos uma nova senha para seu email de login
          </Typography>

          <TextField
            style={{ marginTop: 15, width: '100%'}}
            value={ email }
            onChange={ event => setEmail( event.target.value )}
            variant="outlined"
            label="Email de login"
          />

          <div style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%'
          }}>
            <LoadingButton
              className={classes.button}
              onClick={handleSendPass}
              disabled={ loading }
              loading={ loading }
              variant="contained"
              color="primary"
              size="large"
            >
              Enviar
            </LoadingButton>

            <LoadingButton
              style={{ marginRight: 10 }}
              className={classes.button}
              onClick={handleClose}
              color="primary"
              size="large"
            >
              Cancelar
            </LoadingButton>
          </div>
        </div>
      </Modal>
    </>
  );
}
