import { object, string, date } from 'yup';
import { addYears } from 'date-fns';

import {
  validarCPF,
  validarCNPJ,
} from '~/utils/constants/validators';

export const CADASTRO_INITIAL = {
  proprietarioNome: '',
  proprietarioTipo: '',
  proprietarioRegistro: '',
  inquilinoNome: '',
  inquilinoTipo: '',
  inquilinoRegistro: '',
  vigenciaInicio: new Date(),
  vigenciaFim: addYears(new Date(), 1),
  dataProposta: new Date(),
  localCep: '',
  localEndereco: '',
  localNumero: '',
  localComplemento: '',
  localBairro: '',
  localCidade: '',
  localEstado: '',
  gerarBoleto: 1
};

export const cadastroSchema = object().shape({
  proprietarioNome: string()
    .typeError('Nome do proprietário deve ter formato texto')
    .required('Nome do proprietário é obrigatório'),
  proprietarioTipo: string()
    .typeError('Tipo deve ter formato texto')
    .required('Tipo é obrigatório'),
  proprietarioRegistro: string()
    .typeError('CPF/CNPJ do proprietário deve ter formato texto')
    .required('CPF/CNPJ do proprietário é obrigatório')
    .min(11, 'Digite um CPF ou CNPJ válido')
    .max(14, 'Digite um CPF ou CNPJ válido')
    .test('PropValidationCPF_CNPJ', function ( value ) {
      const { path, createError, parent } = this;
      if( value ) {
        if( parent.proprietarioTipo === 'PF' ) {
          if( !validarCPF( value )) {
            return createError({ path, message: 'CPF inválido' });
          }
        }

        if( parent.proprietarioTipo === 'PJ' ) {
          if( !validarCNPJ( value )) {
            return createError({ path, message: 'CNPJ inválido' });
          }
        }
      }

      return true;
    }),
  inquilinoNome: string()
    .typeError('Nome do inquilino deve ter formato texto')
    .required('Nome do inquilino é obrigatório'),
  inquilinoTipo: string()
    .typeError('Tipo de inquilino deve ter formato texto')
    .required('Tipo de inquilino é obrigatório'),
  inquilinoRegistro: string()
    .typeError('CPF/CNPJ do inquilino deve ter formato texto')
    .required('CPF/CNPJ do inquilino é obrigatório')
    .min(11, 'Digite um CPF/CNPJ válido')
    .max(14, 'Digite um CPF/CNPJ válido')
    .test('InquiValidationCPF_CNPJ', function ( value ) {
      const { path, createError, parent } = this;
      if( value ) {
        if( parent.inquilinoTipo === 'PF' ) {
          if( !validarCPF( value )) {
            return createError({ path, message: 'CPF inválido' });
          }
        }

        if( parent.inquilinoTipo === 'PJ' ) {
          if( !validarCNPJ( value )) {
            return createError({ path, message: 'CNPJ inválido' });
          }
        }
      }

      return true;
    }),
  vigenciaInicio: date()
    .typeError('Início da data de vigência deve ter formato texto')
    .required('Início da data de vigência é obrigatório')
    .test('validaInicioVigencia', function ( value ) {
      const { path, createError } = this;
      if( value ) {
        const date = new Date( value );
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);

        const dateToday = new Date();
        dateToday.setUTCHours(0);
        dateToday.setUTCMinutes(0);
        dateToday.setUTCSeconds(0);
        dateToday.setUTCMilliseconds(0);

        if( date.getTime() < dateToday.getTime()) {
          return createError({ path, message: 'Data abaixo do limite permitido' });
        }

        return true;
      }
    }),
  vigenciaFim: date()
    .typeError('Fim da data de vigência deve ter formato texto')
    .required('Fim da data de vigência é obrigatório'),
  localCep: string()
    .typeError('CEP deve ter formato texto')
    .required('CEP é obrigatório'),
  localEndereco: string()
    .typeError('Logradouro deve ter formato texto')
    .required('Logradouro é obrigatório'),
  localComplemento: string()
    .typeError('Complemento deve ter formato texto')
    .required('Complemento é obrigatório'),
  localBairro: string()
    .typeError('Bairro deve ter formato texto')
    .required('Bairro é obrigatório'),
  localCidade: string()
    .typeError('Cidade deve ter formato texto')
    .required('Cidade é obrigatório'),
  localEstado: string()
    .typeError('UF deve ter formato texto')
    .required('UF é obrigatório'),
  gerarBoleto: string().typeError('Gerar boleto deve ter formato texto')
});
