import { useState, useCallback, useMemo } from 'react';

import api from '~/services/api';

export default function useImobiliaria({ alert, checkApiCall }) {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(false);

  const getImobiliaria = useCallback(async () => {
    setFetching(true);

    try {
      const { data: imobiliariaData } = await checkApiCall(api.getImobiliaria);

      setData(imobiliariaData);

      return { success: true };
    } catch (error) {
      alert.addToast({ message: 'Erro ao buscar dados da imobiliária' });
      return { success: false };
    } finally {
      setFetching(false);
    }
  }, [checkApiCall, alert]);

  return useMemo(
    () => ({
      data,
      fetching,
      getImobiliaria
    }),
    [data, fetching, getImobiliaria]
  );
}
