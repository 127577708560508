export function formataNumeroTelefone(ddd, numero) {
  var length = numero.length;
  var telefoneFormatado;

  if (length === 8) {
    telefoneFormatado = `(` + ddd + `) ` + numero.substring(0, 4) + `-` + numero.substring(4, 8);
  } else if (length === 9) {
    telefoneFormatado = `(` + ddd + `) ` + numero.substring(0, 5) + `-` + numero.substring(5, 9);
  }
  return telefoneFormatado;
}
