import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  stepper: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  step: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  contentContainer: {
    padding: theme.spacing(4)
  },
}));

export default Styles;
