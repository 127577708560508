import React, { useState } from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { AddUserModal } from '../../AddUser';
import useStyles from './UsersTableToolbar.styles';

export function UsersTableToolbar({ getUsers }) {
  const [addUserOpen, setAddUserOpen] = useState(false);

  const classes = useStyles();

  function handleAddUser() {
    setAddUserOpen(true);
  }

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="usuarios">
        Usuários
      </Typography>

      <Tooltip title="Adicionar usuário">
        <IconButton className={ classes.addUser } onClick={handleAddUser}>
          <PersonAddIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      <AddUserModal getUsers={ getUsers } open={addUserOpen} close={() => setAddUserOpen(false)} />
    </Toolbar>
  );
}
