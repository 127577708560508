import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.6)
  },
  buttonsWrapper: {
    marginTop: theme.spacing(6),
    alignSelf: 'flex-end',
    display: 'flex'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  box: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  iconButton: {
    marginLeft: theme.spacing(3),
  }
}));

export default Styles;
