import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  appBar: {
    paddingTop: 5,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  logo: {
    width: 120,
  },

  toolbar: {
    paddingRight: 24
  },
  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  versionTest: {
    position: 'fixed',
    top: 30,
    left: -35,
    padding: 6,
    borderRadius: 4,
    backgroundColor: '#FF0000',
    transform: 'rotate(-40deg)'
  },

  textVersionTest: {
    fontSize: 14,
    marginRight: 30,
    marginLeft: 30,
    fontWeight: 'bold',
    color: '#FFF',
  },
}));

export default Styles;
