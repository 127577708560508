import React from 'react';
import { useField } from 'formik';

import { TextField } from '~/components';

import useStyles from './FormikTextField.styles';

export function FormikTextField({ name, children, ...rest }) {
  const [field, meta] = useField(name);

  const classes = useStyles();

  return (
    <TextField
      error={meta.touched ? !!meta.error : undefined}
      helperText={meta.touched ? meta.error : undefined}
      FormHelperTextProps={{ className: classes.helperText }}
      {...field}
      {...rest}
    >
      {children}
    </TextField>
  );
}
