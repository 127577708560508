import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'formik';

import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FormikRadio, FormikCurrency, FormikTextField } from '~/components';
import api from '~/services/api';
import { useStore } from '~/store';
import { transformOcupacoes } from '~/utils/helpers';
import {
  tiposImovel,
  tiposOcupacaoResidencial,
  NAO_CONTRATAR,
  COMERCIAL,
  tiposCobertura,
  tiposAssistencia,
  tiposAssistenciaBasica,
} from '~/utils/constants';

import { transformAssistencias } from '~/utils/helpers';

import useStyles from './styles';

export function SimulacaoForm({ formik, setOpen }) {
  const { alert } = useStore();

  const [fetchingOcupacoes, setFetchingOcupacoes] = useState(false);

  const [ moreAssistencias, setMoreAssistencias ] = useState(false);

  const [ positionValue, setPositionValue ] = useState(null);

  const [ assistencias, setAssistencias ] = useState([]);

  const [atividades, setAtividades] = useState([]);

  const classes = useStyles();

  const getTiposOcupacao = useCallback(async () => {
    setFetchingOcupacoes(true);

    try {
      const { data } = await api.getOcupacoes();

      const transformedData = transformOcupacoes({ data });

      setAtividades(transformedData);
    } catch {
      alert.addToast({ message: 'Erro inesperado ao buscar ocupações' });
    } finally {
      setFetchingOcupacoes(false);
    }
  }, [alert]);

  async function getAssistencias() {
    if( formik.values.tipoImovel !== '') {
      try {
        const { data: assistenciasData } = localStorage.getItem('@TDA_token')
          ? await api.getAssistencias({
              tipo: formik.values.tipoImovel
            })
          : await api.getAssistenciasExterna({
              tipo: formik.values.tipoImovel
            })

        const transformedData = transformAssistencias({
          data: assistenciasData,
          tipoImovel: formik.values.tipoImovel
        });

        setAssistencias(transformedData);
      } catch( error ) {
        alert.addToast({
          message: 'Erro inesperado ao buscar valores das assistências'
        });
      }
    }
  };

  useEffect(() => {
    setAssistencias([]);

    getAssistencias();
  }, [ formik.values.tipoImovel ]);

  useEffect(() => {
    if (formik.values.tipoImovel === COMERCIAL && !atividades.length) {
      getTiposOcupacao();
    }
  }, [formik.values.tipoImovel, atividades, getTiposOcupacao]);

  useEffect(() => {
    if( localStorage.getItem('TDA_Seguros@Simulacao')) {
      const {
        tipoImovel,
        tipoOcupacao,
        // tipoAtividade,
        tipoCobertura,
        valorAluguel,
        assistencia24,
      } = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      formik.setFieldValue('tipoImovel', tipoImovel, true );
      formik.setFieldValue('tipoOcupacao', tipoOcupacao, true );
      formik.setFieldValue('tipoCobertura', tipoCobertura, true );
      formik.setFieldValue('valorAluguel', parseFloat(valorAluguel), true);
      formik.setFieldValue('assistencia24', assistencia24, true );

      const tipoAtividade = JSON.parse( localStorage.getItem('@TDA_tipoAtividadeStorage'));

      onChangeTipoAtividade( tipoAtividade );

      setMoreAssistencias( assistencia24 > 1 );
    }
  }, [ localStorage.getItem('TDA_Seguros@Simulacao')]);

  function onChangeTipoAtividade(value) {
    setPositionValue( value );

    localStorage.setItem('@TDA_tipoAtividadeStorage', JSON.stringify( value ));

    formik.setFieldValue( 'tipoAtividade', value ? value.value : '', true );
  };

  function handleMoreAssistencias( event ) {
    event.preventDefault();

    if( moreAssistencias ) {
      formik.setFieldValue('assistencia24', '1', true );
    }

    setMoreAssistencias( !moreAssistencias );
  };

  return (
    <Form className={classes.form}>
      <div className={ classes.inputMoney }>
        <FormikCurrency name="valorAluguel" autoFocus label="Valor do Aluguel" />
      </div>

      <FormikRadio
        aria-label="tipo imóvel"
        name="tipoImovel"
        label="Tipo do Imóvel"
        tipoImovel={ formik.values.tipoImovel }
        options={tiposImovel}
      />
      {formik.values.tipoImovel === COMERCIAL ? (
        <>
          <Autocomplete
            options={atividades}
            value={ positionValue }
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => onChangeTipoAtividade( value )}
            renderInput={(params) => <FormikTextField {...params} size="small" name="tipoAtividade" className={classes.selectOcupacao} label="Tipo de Atividade" variant="outlined" />}
          />

          <FormikRadio
            aria-label="tipo cobertura"
            name="tipoCobertura"
            label="Tipo de Cobertura"
            options={tiposCobertura}
          />
        </>
      ) : formik.values.tipoImovel !== '' ? (
        <FormikRadio
          aria-label="tipo ocupação"
          name="tipoOcupacao"
          label="Tipo de Ocupação"
          options={tiposOcupacaoResidencial}
        />
      ): <></>}

      <FormikRadio
        aria-label="Assistência 24 horas"
        name="assistencia24"
        label="Assistência 24 horas"
        showAssistencia={ setOpen }
        disableButtonIcon={ formik.values.tipoImovel !== '' && assistencias !== []}
        assistencias={ assistencias }
        options={ moreAssistencias ? tiposAssistencia : tiposAssistenciaBasica }
      />

      <Link href="#" onClick={ handleMoreAssistencias } color="primary">{ !moreAssistencias ? 'Outras Opções de Assistência 24 Horas' : 'Mostrar Menos'}</Link>
    </Form>
  );
}
