import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'

import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ModalContact from '../../../../../components/ModalContact';

import { appRoutes } from '~/routes/appRoutes'

import useStyles from './DrawerItens.styles'

function RouteItem ({ route, nested }) {
  const { Icon } = route

  const [ open, setOpen ] = useState(false);

  const history = useHistory()

  const classes = useStyles()

  const listItemClass = clsx({
    [classes.nested]: nested
  })

  const match = useRouteMatch(route.path)

  function handleNavigate () {
    localStorage.removeItem('TDA_Seguros@Simulacao');
    localStorage.removeItem('TDA_Seguros@SimulacaoStorage');
    localStorage.removeItem('TDA_Seguros@SimulacaoValoresDeletados');
    localStorage.removeItem('TDA_SEGUROS@ACAO')
    localStorage.removeItem('@TDA_tipoAtividadeStorage')

    history.push(route.path)
  }

  function handleModal() {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        style={ route.path === '/app/seguro-incendio' ? { backgroundColor: '#F44336' } : route.path === '/app/modal-contato' ? { backgroundColor: '#3f51b5' } : {}}
        className={listItemClass}
        button
        onClick={ route.path === '/app/modal-contato' ? handleModal : handleNavigate}
        selected={!!match}
      >
        <ListItemIcon>
          <Icon style={ route.path === '/app/seguro-incendio' || route.path === '/app/modal-contato' ? { color: '#FFF' } : {}} className={!!match ? classes.active : undefined} />
        </ListItemIcon>
        <ListItemText
          style={ route.path === '/app/seguro-incendio' || route.path === '/app/modal-contato' ? { color: '#FFF' } : {}}
          className={!!match ? classes.active : undefined}
          primary={route.label}
        />
      </ListItem>

      <ModalContact open={ open } handleModal={ handleModal }/>
    </>
  )
}

function NestedRouteItem ({ route }) {
  const [open, setOpen] = useState(false)

  const match = useRouteMatch(route.path)

  const { Icon } = route

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)} selected={!!match}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={route.label} />
        {open ? <ExpandLessIcon color='action' /> : <ExpandMoreIcon color='action' />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {route.nested.map(nestedRoute => (
            <RouteItem key={nestedRoute.path} route={nestedRoute} nested />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export function DrawerItens () {
  return (
    <List>
      {appRoutes.map(route =>
        route.nested ? (
          <NestedRouteItem key={route.path} route={route} />
        ) : (
          <RouteItem key={route.path} route={route} />
        )
      )}
    </List>
  )
}
