import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  table: {
    flex: 1,
    display: 'flex'
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    '& > *:nth-child(odd)': {
      background: theme.palette.grey[100]
    }
  },
  progressContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    padding: theme.spacing(1.5),
    borderRadius: '50%'
  },
  hiddenRow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  row: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItens: 'center',
    flexWrap: 'wrap'
  },
  label: {
    minWidth: 200,
    flex: 2,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  },
  content: {
    minWidth: 200,
    flex: 3,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export default Styles;
