/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useField } from 'formik';

import { phoneMask } from '~/utils/helpers/masks';
import { TextFieldMask } from '~/components/Inputs';

export function FormikMask(props) {
  const [field, meta, helpers] = useField(props);
  const [maskedText, setMaskedText] = useState(meta.initialValue || '');

  useEffect(() => {
    const raw = maskedText.replace(/\D+/g, '');

    if (raw !== field.value) {
      setMaskedText(field.value);
    }
  }, [field.value]);

  function handleChange(e) {
    const { value } = e.target;

    const raw = value ? value.replace(/\D+/g, '') : value;

    helpers.setValue(raw);
    setMaskedText(value);
  }

  return (
    <TextFieldMask
      error={meta.touched ? !!meta.error : undefined}
      helperText={meta.touched ? meta.error : undefined}
      mask={phoneMask}
      value={maskedText}
      name={field.value}
      onBlur={field.onBlur}
      {...props}
      onChange={handleChange}
    />
  );
}
