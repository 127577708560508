import { object, string, number } from 'yup';

export const CONTACT_INITIAL = {
  tpContato: '',
  nome: '',
  telefone: '',
  celular: '',
  email: ''
};

export const contactSchema = object().shape({
  tpContato: number()
    .typeError('Tipo de contato deve ser número')
    .required('Tipo é obrigatório'),
  nome: string()
    .typeError('Nome deve ter formato texto')
    .required('Nome é obrigatório'),
  telefone: string()
    .typeError('Telefone deve ter formato texto')
    .min(10, 'Número incompleto')
    .required('Telefone é obrigatório'),
  celular: string()
    .typeError('Celular deve ter formato texto')
    .min(10, 'Número incompleto')
    .required('Celular é obrigatório'),
  email: string()
    .typeError('Email deve ter formato texto')
    .required('Email é obrigatório')
    .email('Deve ser um e-mail válido')
});

export const contactsDataTable = [
  { id: 'nome', label: 'Nome' },
  { id: 'tipo', label: 'Tipo' },
  { id: 'email', label: 'Email' },
  { id: 'telefone', label: 'Telefone' },
  { id: 'celular', label: 'Celular' },
  { id: 'actions', label: 'Ações' }
];

export const tiposContato = [
  {
    value: 1,
    label: 'Atendente'
  },
  {
    value: 2,
    label: 'Corretor'
  },
  {
    value: 3,
    label: 'Diretor'
  },
  {
    value: 4,
    label: 'Estagiário'
  },
  {
    value: 5,
    label: 'Financeiro'
  },
  {
    value: 6,
    label: 'Gerente'
  },
  {
    value: 7,
    label: 'Jurídico'
  },
  {
    value: 8,
    label: 'Presidente'
  },
  {
    value: 9,
    label: 'Proprietário'
  },
  {
    value: 10,
    label: 'Sócio'
  },
  {
    value: 11,
    label: 'Vendedor'
  }
];
