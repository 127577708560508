import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  cepWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    '& *:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  cepField: {
    margin: 0,
    width: '72%',
    paddingBottom: theme.spacing(2)
  },
  cepButton: {
    alignSelf: 'stretch',
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  separator: {
    background: theme.palette.grey[300],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    height: 1
  },
  inputsColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 300,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  inputsRow: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5)
  },
  data: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1)
  },

  dataLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 5,
  },
}));

export default Styles;
