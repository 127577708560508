import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.6)
  },
  buttonsWrapper: {
    marginTop: theme.spacing(6),
    alignSelf: 'flex-end',
    display: 'flex'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  box: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  inputsColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    minWidth: 300,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },

  input: {
    fontSize: 18,
  },

  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    width: 680,
    height: 200
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  message: {
    fontSize: 16,
    textAlign: 'center',
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  bodyModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
    padding: 10
  },

  label: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000'
  },

  inputsColumnTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },

  inputsRow: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  separator: {
    background: theme.palette.grey[300],
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: 1
  },
  separatorInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 1
  },
}));

export default Styles;
