import React, { useEffect, useState } from 'react';
import { useField } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './FormikRadio.styles';

import { PDFDownloadLink } from '@react-pdf/renderer';

import MyDocument from '../../../utils/template/assistencia24';

export function FormikRadio({ name, label, children, options, disableButtonIcon, showAssistencia, assistencias, ...rest }) {
  const [field, meta] = useField(name);

  const classes = useStyles();

  const showError = meta.touched && !!meta.error;

  return (
    <FormControl
      component="fieldset"
      className={classes.container}
      error={meta.touched ? !!meta.error : undefined}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup className={classes.radioGroup} {...field} {...rest}>
        {options.map(option => (
          <div className={ classes.rowRadio }>
            <FormControlLabel
              key={option.value}
              control={<Radio size="small" className={classes.radio} />}
              {...option}
            />

            {
              option.helpText
                ? <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ option.helpText }</span>} placement="right">
                    <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                  </Tooltip>
                : <></>
            }

            {
              option.showAssistencia
                ? assistencias.length !== 0
                    ? <PDFDownloadLink document={<MyDocument tipo={ option } data={ assistencias }/>} fileName="assistencias.pdf">
                        {({ blob, url, loading, error }) => (
                          loading
                            ? <span>LOADING...</span>
                            : <Button color="primary" startIcon={ <VisibilityIcon />}>
                                Veja as Coberturas
                              </Button>
                          )}
                      </PDFDownloadLink>
                    : <Button disabled color="primary" startIcon={ <VisibilityIcon />}>
                        Veja as Coberturas
                      </Button>
                : <></>
            }
          </div>
        ))}
      </RadioGroup>
      {showError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
