import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  progressContainer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    padding: theme.spacing(1.5),
    borderRadius: '50%'
  },
  hiddenRow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  toolbar: ({ sm }) =>
    !sm && {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1)
    },
  selectRoot: ({ sm }) =>
    !sm && {
      margin: 0
    },
  actions: ({ sm }) =>
    !sm && {
      margin: 0,
      alignSelf: 'stretch',
      display: 'flex',
      justifyContent: 'space-between'
    }
}));

export default Styles;
