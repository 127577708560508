import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    overflow: 'hidden'
  },
  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },
  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  progress: {
    margin: theme.spacing(0.6),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    flex: 1,
    minWidth: 200,
    maxWidth: 600,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  inputsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1
  },

  fab: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },

  popover: {
    pointerEvents: 'none',
  },

  paper: {
    padding: theme.spacing(1),
  },
}));

export default Styles;
