import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.6),
    paddingTop: theme.spacing(1.4),
    width: '90%',
    minWidth: 300,
    maxWidth: 550,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    outline: 'none'

  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  }
}));

export default Styles;
