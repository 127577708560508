import { object, string, ref } from 'yup';

export const USER_KEY = '@TDA_user';
export const USUARIO_KEY = '@TDA_usuario';

export const USER_INITIAL = {
  usuario: '',
  senha: '',
  confirmarSenha: '',
  nome: '',
  cargo: '',
  status: '',
  role: ''
};

export const userSchema = object().shape({
  usuario: string()
    .typeError('Email deve ter formato texto')
    .required('Email é obrigatório')
    .email('Deve ser um e-mail válido'),
  senha: string()
    .typeError('Senha deve ter formato texto')
    .required('Senha é obrigaória'),
  confirmarSenha: string()
    .typeError('Confirmação de senha deve ter formato texto')
    .when('senha', (password, field) =>
      password
        ? field
            .required('Confirme a senha')
            .oneOf([ref('senha')], 'Senhas não conferem')
        : field
    ),
  nome: string()
    .typeError('Nome deve ter formato texto')
    .required('Nome é obrigatório'),
  cargo: string()
    .typeError('Cargo deve ter formato texto')
    .required('Cargo é obrigatório'),
  status: string()
    .typeError('Status deve ter formato texto')
    .required('Status é obrigatório'),
  role: string()
    .typeError('Nível de acesso deve ter formato texto')
    .required('Nível de acesso é obrigatório')
});

export const usersDataTable = [
  { id: 'nome', label: 'Nome' },
  // { id: 'imobiliaria', label: 'Imobiliária' },
  { id: 'usuario', label: 'E-mail' },
  { id: 'cargo', label: 'Cargo' },
  { id: 'status', label: 'Status' },
  { id: 'role', label: 'Nível' }
];
