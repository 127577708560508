import React from 'react';

import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EnvironmentBox from '~/components/EnvironmentBox';
import ErrorBoundary from '~/components/ErrorBoundary';

import styles from './styles';
import { AppBar } from './AppBar';
import { AppDrawer } from './AppDrawer';
import { MobileAppDrawer } from './MobileAppDrawer';

export function AppLayout({ children }) {
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = styles();

  return (
    <div className={classes.root}>
      <AppBar drawerOpen={open} openDrawer={() => setOpen(!open)} />
      {sm ? (
        <AppDrawer drawerOpen={open} closeDrawer={() => setOpen(false)} />
      ) : (
        <MobileAppDrawer drawerOpen={open} closeDrawer={() => setOpen(false)} />
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ErrorBoundary>{children}</ErrorBoundary>
          <EnvironmentBox />
        </Container>
      </main>
    </div>
  );
}
