import React, { useCallback, useState, useEffect } from 'react';
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Toolbar from '@material-ui/core/Toolbar';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import { useHistory } from 'react-router-dom';

import { toCurrency } from '~/utils/helpers';
import { format } from 'date-fns';

import { LoadingButton } from '~/components';
import { useStore } from '~/store';
import { CADASTRO_INITIAL, cadastroSchema } from '~/utils/constants';

import useStyles from './styles';
import { PropostaForm } from './PropostaForm';

import api from '~/services/api';
import { Button } from '@material-ui/core';

export function Confirmacao({ activeStep, setActiveStep, setOpen, setProposta, handleNext }) {
  const logged = localStorage.getItem('@TDA_token');

  const history = useHistory();

  const { simulacao, alert } = useStore();

  const [sending, setSending] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openModalSave, setOpenModalSave ] = useState(false);

  const [ simulacaoStorage, setSimulacaoStorage ] = useState({});

  const [ pkProposta, setPkProposta ] = useState(null);
  const [ propostaLocal, setPropostaLocal ] = useState({});

  const classes = useStyles();

  function textAssistencia( value ) {
    switch( value ) {
      case '1': return 'Básica';
      case '2': return 'Intermediária';
      case '3': return 'Completa';
      default: return 'Não Contratado';
    }
  };

  async function saveUpdateProposta( confirmFormData ) {
    try {
      setSending(true);

      const data = {
        imovelTipoImovel: simulacaoStorage.imovelTipoImovel,
        imovelTipoOcupacao: simulacaoStorage.imovelTipoOcupacao,
        imovelCodigoAtividade: simulacaoStorage.imovelCodigoAtividade,
        valorAluguel: simulacaoStorage.valorAluguel,
        assistencia24H: simulacaoStorage.assistencia24H,
        assistencia24HText: textAssistencia( simulacaoStorage.assistencia24H ),
        seguradora: simulacaoStorage.pkSeguradora,
        qtdParcelas: simulacaoStorage.qtdParcelas,
        valorSegurado: toCurrency( simulacaoStorage.incendio_raio_explosao ),
        valorPagamentoAluguel: toCurrency(
          simulacaoStorage.perda_pagamento_aluguel
        ),
        valorCoberturaResponsabilidadeCivil: toCurrency(
          simulacaoStorage.responsabilidade_civil
        ),
        valorCoberturaVendavalGranizo: toCurrency(
          simulacaoStorage.quebra_vidros
        ),
        valorCoberturaDanosEletricos: toCurrency(
          simulacaoStorage.danos_eletricos
        ),
        premioLiquido: toCurrency(simulacaoStorage.valorPremioLiquidoTotal),
        premioTotal: toCurrency(simulacaoStorage.valorPremioTotal),
        pkPropostaUpdate: simulacaoStorage.pkProposta,
        proprietarioNome: confirmFormData.proprietarioNome,
        proprietarioTipo: confirmFormData.proprietarioTipo,
        proprietarioRegistro: confirmFormData.proprietarioRegistro,
        inquilinoNome: confirmFormData.inquilinoNome,
        inquilinoTipo: confirmFormData.inquilinoTipo,
        inquilinoRegistro: confirmFormData.inquilinoRegistro,
        vigenciaInicio: format(confirmFormData.vigenciaInicio, 'dd/MM/yyyy'),
        vigenciaFim: format(confirmFormData.vigenciaFim, 'dd/MM/yyyy'),
        dataProposta: confirmFormData.dataProposta,
        localCep: confirmFormData.localCep,
        localEndereco: confirmFormData.localEndereco,
        localNumero: confirmFormData.localNumero,
        localComplemento: confirmFormData.localComplemento,
        localBairro: confirmFormData.localBairro,
        localCidade: confirmFormData.localCidade,
        localEstado: confirmFormData.localEstado,
        gerarBoleto: confirmFormData.gerarBoleto,
        diasAteVencimentoBoleto: 5,
      };

      const response = await api.alterarProposta( pkProposta, data );

      if( response.statusText === 'OK') {
        localStorage.removeItem('TDA_Seguros@Simulacao');

        setPropostaLocal( response.data );
        setOpenModalSave( true );

        alert.addToast({
          type: 'success',
          message: 'Proposta alterada com sucesso!'
        });
      } else {
        alert.addToast({
          message: 'Não foi possível confirmar o seguro, tente novamente'
        });

        setSending(false);
      }
    } catch( error ) {
      alert.addToast({ message: 'Erro ao confirmar seguro' });

      setSending(false);
    }
  };

  async function savePropostaCotacao( confirmFormData ) {
    try {
      setSending(true);

      const data = {
          imovelTipoImovel: simulacaoStorage.imovelTipoImovel,
          imovelTipoOcupacao: simulacaoStorage.imovelTipoOcupacao,
          imovelCodigoAtividade: simulacaoStorage.imovelCodigoAtividade,
          valorAluguel: simulacaoStorage.valorAluguel,
          assistencia24H: simulacaoStorage.assistencia24H,
          assistencia24HText: textAssistencia( simulacaoStorage.assistencia24H ),
          seguradora: simulacaoStorage.pkSeguradora,
          qtdParcelas: simulacaoStorage.qtdParcelas,
          valorSegurado: toCurrency( simulacaoStorage.coberturaIncendioRaioExplosao ),
          valorPagamentoAluguel: toCurrency(
            simulacaoStorage.coberturaPerdaPagamentoAluguel
          ),
          valorCoberturaResponsabilidadeCivil: toCurrency(
            simulacaoStorage.coberturaResponsabilidadeCivil
          ),
          valorCoberturaVendavalGranizo: toCurrency(
            simulacaoStorage.coberturaQuebraVidros
          ),
          valorCoberturaDanosEletricos: toCurrency(
            simulacaoStorage.coberturaDanosEletricos
          ),
          premioLiquido: toCurrency(simulacaoStorage.valorPremioLiquidoTotal),
          premioTotal: toCurrency(simulacaoStorage.valorPremioTotal),
          pkPropostaUpdate: simulacaoStorage.pkProposta,
          proprietarioNome: confirmFormData.proprietarioNome,
          proprietarioTipo: confirmFormData.proprietarioTipo,
          proprietarioRegistro: confirmFormData.proprietarioRegistro,
          inquilinoNome: confirmFormData.inquilinoNome,
          inquilinoTipo: confirmFormData.inquilinoTipo,
          inquilinoRegistro: confirmFormData.inquilinoRegistro,
          vigenciaInicio: format(confirmFormData.vigenciaInicio, 'dd/MM/yyyy'),
          vigenciaFim: format(confirmFormData.vigenciaFim, 'dd/MM/yyyy'),
          dataProposta: confirmFormData.dataProposta,
          localCep: confirmFormData.localCep,
          localEndereco: confirmFormData.localEndereco,
          localNumero: confirmFormData.localNumero,
          localComplemento: confirmFormData.localComplemento,
          localBairro: confirmFormData.localBairro,
          localCidade: confirmFormData.localCidade,
          localEstado: confirmFormData.localEstado,
          gerarBoleto: confirmFormData.gerarBoleto,
          diasAteVencimentoBoleto: 5,
      };

      const response = await api.enviarPropostaSeguro( data );

      if( response.statusText === 'OK') {
        localStorage.removeItem('TDA_Seguros@Simulacao');

        setPropostaLocal( response.data );
        setOpenModalSave( true );

        alert.addToast({
          type: 'success',
          message: 'Proposta enviada com sucesso!'
        });
      } else {
        alert.addToast({
          message: 'Não foi possível confirmar o seguro, tente novamente'
        });

        setSending(false);
      }
    } catch( error ) {
      alert.addToast({ message: 'Erro ao confirmar seguro' });

      setSending(false);
    }
  };

  async function handleSubmit( confirmFormData ) {
    if( pkProposta ) {
      saveUpdateProposta( confirmFormData );
    } else {
      if( simulacaoStorage.pkProposta ) {
        savePropostaCotacao( confirmFormData );
      } else {

        try {
          setSending(true);

          const simulacaoJson = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

          confirmFormData.qtdParcelas = simulacaoJson.qtdParcelas;

          const { data, success } = await simulacao.confirmarSeguro({
            confirmFormData
          });
          setSending(false);

          data.assistencia24HText = textAssistencia( data.assistencia24H );

          window.scrollTo( 1, 1 );

          localStorage.removeItem('TDA_Seguros@Simulacao');

          setPropostaLocal( data );
          setOpenModalSave( true );

          alert.addToast({
            type: 'success',
            message: 'Proposta enviada com sucesso!'
          });

        } catch( error ) {
          alert.addToast({
            message: 'Não foi possível confirmar o seguro, tente novamente'
          });
        }
      }
    }
  };

  const handleLogin = () => {
    history.push('/login');
  };

  function handleCloseModalSave() {
    setOpenModalSave( false );

    setProposta( propostaLocal );
    setOpen( true );

    setActiveStep(0);
  };

  function savePropostaStorage( data ) {
    const simulacaoJson = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    data.qtdParcelas = simulacaoJson.qtdParcelas;

    localStorage.setItem('TDA_Seguros@NewProposta', JSON.stringify( data ));

    setOpenModal( true );
  };

  function handlePreSubmit( data ) {
    if( logged ) {
      handleSubmit( data );
    } else {
      savePropostaStorage( data );
    }
  };

  useEffect(() => {
    const simulacao = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    if( simulacao ) {
      setPkProposta( simulacao.pkProposta );

      const simulacaoData = {
        ...simulacao.coberturasExibir,
        premioLiquido: simulacao.premioLiquido,
        tipoCobertura: simulacao.tipoCobertura,
        premioTotal: simulacao.premioTotal,
        dscaminhologo: simulacao.dscaminhologo,
        nomeSeguradora: simulacao.seguradora.nome,
        pkProposta: simulacao.pkProposta,
        imovelTipoImovel: simulacao.tipoImovel,
        imovelTipoOcupacao: simulacao.tipoOcupacao,
        imovelCodigoAtividade: simulacao.tipoAtividade,
        valorAluguel: simulacao.valorAluguel,
        assistencia24H: simulacao.coberturaAssistencia24H,
        pkSeguradora: simulacao.pkSeguradora || simulacao.seguradora.pkSeguradoras,
        coberturaIncendioRaioExplosao: simulacao.incendioraioexplosao,
        coberturaPerdaPagamentoAluguel: simulacao.perdapagamentoaluguel,
        coberturaResponsabilidadeCivil: simulacao.responsabilidadecivil,
        coberturaQuebraVidros: simulacao.vendavalGranizo,
        coberturaDanosEletricos: simulacao.danoseletricos,
        valorPremioLiquidoTotal: simulacao.premioLiquido,
        valorPremioTotal: simulacao.premioTotal,
        qtdParcelas: simulacao.qtdParcelas,
      };

      setSimulacaoStorage( simulacaoData );
    }
  }, [ localStorage.getItem('TDA_Seguros@Simulacao') ]);

  return (
    <>
      <Box className={classes.box}>
        <Typography component="span" variant="h6" color="primary" gutterBottom>
          Seguro Contratado
        </Typography>

        <Box className={classes.inputsRowTop}>
          <Box className={classes.inputsColumnTop}>
            <img style={{ width: 100 }}  src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${simulacaoStorage.dscaminhologo}`} alt=""/>
          </Box>

          <Box className={classes.inputsColumn }>
            <TextField
              value={ simulacaoStorage.nomeSeguradora }
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Seguradora"
            />
          </Box>
        </Box>

        <Box className={classes.separatorInput} />

        <Box className={classes.inputsRow}>
          <Box className={classes.inputsColumn}>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.incendio_raio_explosao )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Incêndio/Raio/Explosão"
            />
          </Box>

          <Box className={ classes.inputsColumn }>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.perda_pagamento_aluguel )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Perda/Pagamento Aluguel"
            />
          </Box>
        </Box>

        <Box className={classes.separatorInput} />

        <Box className={ classes.inputsRow }>
          <Box className={ classes.inputsColumn }>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.responsabilidade_civil )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Responsabilidade Civil"
            />
          </Box>

          <Box className={classes.inputsColumn}>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.quebra_vidros )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Vendaval/Granizo"
            />
          </Box>
        </Box>

        <Box className={classes.separatorInput} />

        <Box className={classes.inputsRow}>
          <Box className={classes.inputsColumn}>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.danos_eletricos )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Danos Elétricos"
            />
          </Box>

          <Box className={classes.inputsColumn}>
            <TextField
              value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format( simulacaoStorage.premioTotal )}
              InputLabelProps={{ classes: { root: classes.label }}}
              InputProps={{ classes: { root: classes.input }}}
              size="small"
              label="Prêmio Total"
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.separator} />

      <Box className={classes.box}>
        <Formik
          onSubmit={handlePreSubmit}
          initialValues={CADASTRO_INITIAL}
          validationSchema={cadastroSchema}
          validateOnMount
        >
          {formik => {
            return (
              <>
                <PropostaForm formik={formik} />

                <Box className={classes.buttonsWrapper}>
                  <LoadingButton
                    className={classes.button}
                    onClick={() => setActiveStep(activeStep - 1)}

                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    size="large"
                  >
                    Voltar
                  </LoadingButton>
                  
                  <LoadingButton
                    className={classes.button}
                    type="submit"
                    form="cadastro_form"
                    variant="contained"
                    loading={sending}
                    color="primary"
                    endIcon={<SendIcon />}
                    size="large"
                  >
                    Confirmar
                  </LoadingButton>
                </Box>
              </>
            );
          }}
        </Formik>
      </Box>

      <Modal
        open={openModalSave}
        onClose={() => setOpenModalSave( false )}
        className={classes.modal}
      >
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6">
              Aviso importante!
            </Typography>
          </Toolbar>
          <div className={ classes.bodyModal }>
            <span className={ classes.message }>Em alguns minutos você irá receber a proposta e o boleto em seu e-mail cadastrado.</span>

            <Button
              onClick={ handleCloseModalSave }
              variant="contained"
              color="primary"
              size="large"
            >
              OK
            </Button>
          </div>
        </Paper>
      </Modal>

      <Modal
        open={openModal}
        onClose={() => setOpenModal( false )}
        className={classes.modal}
      >
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6">
              COMO DESEJA PROSSEGUIR?
            </Typography>
          </Toolbar>

          <div className={ classes.bodyModal }>
            <Button
              onClick={ handleLogin }
              variant="contained"
              color="primary"
              size="large"
            >
              Já sou cadastrado na TDA Corretora de Seguros
            </Button>

            <Button
              onClick={() => handleNext()}
              variant="contained"
              color="primary"
              size="large"
            >
              Não sou cadastrado na TDA Corretora de Seguros. Quero me cadastrar
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
}
