import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import SendIcon from '@material-ui/icons/Send';
import MenuItem from '@material-ui/core/MenuItem';

import { USER_INITIAL, statusOptions, userSchema } from '~/utils/constants';
import { FormikRadio, FormikTextField, LoadingButton } from '~/components';

import useStyles from './AddUserForm.styles';
import { useStore } from '~/store';

import api from '~/services/api';

const niveis = require('~/utils/mock/niveis.json');

export function AddUserForm({ selectedUser, getUsers, close }) {
  const [sending, setSending] = useState(false);

  // const { auth } = useStore();
  const auth = JSON.parse( localStorage.getItem('@TDA_user'));

  const { alert } = useStore();

  const classes = useStyles();

  async function handleSubmit(data, options) {
    setSending(true);

    try {
      if( !selectedUser ) {
        const response = await api.addUser( data );

        if (response.statusText === 'OK') {
          alert.addToast({
            type: 'success',
            message: 'Usuário adicionado com sucesso!'
          });

          await getUsers();

          close();

          options.resetForm();
        } else {
          alert.addToast({ message: 'Falha ao cadastrar novo usuário' });
        }
      } else {
        const response = await api.updateUser( selectedUser.usuario, data );

        if (response.statusText === 'OK') {
          alert.addToast({
            type: 'success',
            message: 'Usuário alterado com sucesso!'
          });

          await getUsers();

          close();

          options.resetForm();
        } else {
          alert.addToast({ message: 'Erro ao alterar usuário' });
        }
      }
    } catch( error ) {
      console.error( error );

      alert.addToast({ message: error.response.data.errors[0].msg || 'Erro inesperado' });
    } finally {
      setSending(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={ selectedUser || USER_INITIAL }
      validationSchema={userSchema}
      validateOnMount
    >
      {formik => (
        <Form className={classes.form}>
          <FormikTextField name="nome" label="Nome" />
          <FormikTextField name="usuario" label="E-mail" autoComplete="email" />
          <FormikTextField name="senha" label="Senha" type="password" />
          <FormikTextField
            name="confirmarSenha"
            label="Confirmação de senha"
            type="password"
          />
          <FormikTextField name="cargo" label="Cargo" disabled={ selectedUser && auth.role !== 'AdmImobiliaria' }/>
          <FormikTextField name="role" select label="Nível de Acesso" disabled={ selectedUser && auth.role !== 'AdmImobiliaria' }>
            {niveis.map(nivel => (
              <MenuItem key={nivel.id} value={nivel.value}>
                {nivel.label}
              </MenuItem>
            ))}
          </FormikTextField>
          <FormikRadio
            aria-label="status"
            name="status"
            label="Status"
            options={ selectedUser && auth.role !== 'AdmImobiliaria' ? [{ label: selectedUser.status, value: selectedUser.status }] : statusOptions }
          />
          <LoadingButton
            className={classes.button}
            type="submit"
            disabled={!formik.isValid || sending}
            loading={sending}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            size="large"
          >
            Enviar
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}
