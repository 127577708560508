import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { ConfirmWrapper } from '~/components/ConfirmWrapper';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { getComparator, stableSort, getColumns } from '~/utils/helpers';
import { transformContatos } from '~/utils/helpers/contatos';
import { contactsDataTable } from '~/utils/constants';

import useStyles from './styles';
import { ContactsTableHeader } from './ContactsTableHeader';
import { ContactsTableToolbar } from './ContactsTableToolbar';

import { AddContactModal } from './AddContact';

import { useStore } from '~/store';

import api from '~/services/api';

export function Contacts() {
  const { contacts, alert } = useStore();

  const [ loading, setLoading ] = useState({ load: false, id: 0});

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles({ md, sm });

  const transformedContacts = transformContatos({ data: contacts.data.map(item => {
    const obj = item

    obj.actions = <ActionsRow contact={ item } />

    return obj
  })});

  function ActionsRow({ contact }) {
    const [addContactOpen, setAddContactOpen] = useState(false);
    const [ contactData, setContactData ] = useState({});

    async function handleDelete() {
      setLoading({ load: true, id: contact.pkContatos });

      try {
        const { data } = await api.deleteContact( contact.pkContatos );

        if( data.success ) {
          alert.addToast({
            type: 'success',
            message: 'Imobiliária deletada com sucesso!'
          });

          contacts.getContacts();

          setLoading(false);
        } else {
          alert.addToast({ message: 'Erro inesperado ao deletar' });
        }

        setLoading(false);
      } catch {
        alert.addToast({ message: 'Erro inesperado ao deletar' });
      }
    };

    function handleEdit() {
      setContactData(contact);

      setAddContactOpen(true);
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <IconButton onClick={handleEdit} color="primary">
          <EditIcon />
        </IconButton>

        <ConfirmWrapper
          action="Deletar"
          message="Tem certeza que deseja deletar este usuário?"
          onConfirm={handleDelete}
        >
          <IconButton color="secondary">
            { loading.load && loading.id === contact.pkContatos ? <CircularProgress size={ 20 } style={{ color: '#F00' }}/> : <DeleteIcon /> }
          </IconButton>
        </ConfirmWrapper>

        <AddContactModal
          open={addContactOpen}
          close={() => setAddContactOpen(false)}
          contactData={ contactData }
        />
      </div>
    );
  };

  const columns = getColumns(contactsDataTable, { md, sm });

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nome');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    contacts.getContacts();
  }, []);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, transformedContacts.length - page * rowsPerPage);

  return (
    <Paper elevation={0}>
      <ContactsTableToolbar />
      <TableContainer>
        <Table aria-labelledby="contatos" aria-label="contatos">
          <ContactsTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {contacts.fetching && (
              <TableRow className={classes.hiddenRow}>
                <TableCell>
                  <Paper elevation={4} className={classes.progressContainer}>
                    <CircularProgress size={30} color="primary" thickness={6} />
                  </Paper>
                </TableCell>
              </TableRow>
            )}
            {stableSort(transformedContacts, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    {columns.map(column => (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }} />}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{
          toolbar: classes.toolbar,
          selectRoot: classes.selectRoot,
          actions: classes.actions
        }}
        labelRowsPerPage="Resultados por página"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={transformedContacts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
