export const situacaoOptions = [
  {
    id: 1,
    label: 'Todas',
    value: 'TODAS'
  },
  {
    id: 2,
    label: 'Nova',
    value: 'NOVA'
  },
  {
    id: 3,
    label: 'Nova Alterada',
    value: 'NOVA_ALTERADA'
  },
  {
    id: 4,
    label: 'Alterada',
    value: 'ALTERADA'
  },
  {
    id: 5,
    label: 'Renovada',
    value: 'RENOVADA'
  },
  {
    id: 6,
    label: 'Renovada Alterada',
    value: 'RENOVADA_ALTERADA'
  },
  {
    id: 7,
    label: 'Cancelada',
    value: 'CANCELADA'
  },
  {
    id: 8,
    label: 'Reativada',
    value: 'REATIVADA'
  },
  {
    id: 9,
    label: 'Reativada Alterada',
    value: 'REATIVADA_ALTERADA'
  },
  {
    id: 10,
    label: 'Reativada Renovada',
    value: 'REATIVADA_RENOVADA'
  },
  {
    id: 11,
    label: 'Reativada Renovada Alterada',
    value: 'REATIVADA_RENOVADA_ALTERADA'
  }
];

export const propostasDataTable = [
  { id: 'pkProposta', label: 'Protocolo' },
  { id: 'nomeUsuario', label: 'Usuário' },
  { id: 'proprietarioNome', label: 'Proprietário' },
  { id: 'inquilinoNome', label: 'Inquilino' },
  { id: 'nomeSeguradora', label: 'Seguradora' },
  { id: 'vigencia_start', label: 'Vigência Início' },
  { id: 'vigencia_end', label: 'Vigência Fim' },
  { id: 'acoes', label: 'Ações' },
];

export const propostasDataTableProp = [
  { id: 'pkProposta', label: 'Protocolo' },
  { id: 'nomeUsuario', label: 'Usuário' },
  { id: 'proprietarioNome', label: 'Proprietário' },
  { id: 'inquilinoNome', label: 'Inquilino' },
  { id: 'nomeSeguradora', label: 'Seguradora' },
  { id: 'acoes', label: 'Ações' },
];
