import React, { useState, useEffect } from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { LoadingButton } from '~/components';

import { makeStyles } from '@material-ui/core/styles';

import { useStore } from '~/store';

import {
  BlobProvider,
} from '@react-pdf/renderer';

import MyDocument from '../../utils/template/cotacao';

import { addYears, addDays, startOfDay } from 'date-fns';

import {
  useHistory,
} from 'react-router-dom';

import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    width: 450,
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  inputView: {
    padding: 5,
  },

  input: {
    width: '100%',
  },

  button: {
    marginLeft: theme.spacing(1)
  },

  seguradoraView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '40%',
    marginBottom: 15,
  },

  imgSeguradora: {
    width: 50,
  },

  actionsView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  }
}));

export default function ModalCotacao({ open, setOpen }) {
  const classes = useStyles();

  const { simulacao, alert } = useStore();

  const [ seguradora1, setSeguradora1 ] = useState( false );
  const [ seguradora2, setSeguradora2 ] = useState( false );
  const [ seguradora3, setSeguradora3 ] = useState( false );

  const [ loading, setLoading ] = useState(false);

  const [ sendEmail, setSendEmail ] = useState(null);

  const [ nome, setNome ] = useState('');
  const [ email, setEmail ] = useState('');

  const [ dataVigencia, setDataVigencia ] = useState('');

  const [ cotacaoResponse, setCotacaoResponse ] = useState(null);

  const history = useHistory();

  async function handleSaveCotacao() {
    const simulacaoStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    const arraySimulacao = [];

    if( seguradora1 ){
      arraySimulacao.push( simulacao.data[0]);
    }

    if( seguradora2 ){
      arraySimulacao.push( simulacao.data[1]);
    }

    if( seguradora3 ){
      arraySimulacao.push( simulacao.data[2]);
    }

    if( arraySimulacao.length > 0 || nome !== '' ){
      setLoading(true);

      try {
        const inicio = new Date();

        const response = await api.salvarCotacao({
          assistencia24H: simulacaoStorage.assistencia24,
          valorAluguel: simulacaoStorage.valorAluguel,
          tipoCobertura: simulacaoStorage.tipoCobertura,
          imovelTipoImovel: simulacaoStorage.tipoImovel,
          imovelTipoOcupacao: simulacaoStorage.tipoOcupacao,
          imovelCodigoAtividade: simulacaoStorage.tipoAtividade,
          dataProposta: new Date(Date.UTC(inicio.getFullYear(), inicio.getMonth(), inicio.getDate(), 0, 0, 0)),
          vigenciaInicio: new Date(Date.UTC(inicio.getFullYear(), inicio.getMonth(), inicio.getDate(), 0, 0, 0)),
          vigenciaFim: addYears( new Date(Date.UTC(inicio.getFullYear(), inicio.getMonth(), inicio.getDate(), 0, 0, 0)), 1),
          coberturas: arraySimulacao,
          vigenciaFimCotacao: dataVigencia.vigencia,
          cotacaoNomeInteressado: nome,
          cotacaoEmailInteressado: email,
        });

        if( response.statusText === 'OK') {
          if( email !== '') {
            setCotacaoResponse( response.data );
          } else {
            setLoading( false );
            setOpen( false );

            window.scrollTo( 1, 1 );
            history.push('/app/cotacoes');
          }

          alert.addToast({ type: 'success', message: 'Cotação salva com sucesso!' });
        } else {
          alert.addToast({ message: 'Erro ao salvar cotação' });

          setLoading( false );
        }
      } catch ( error ) {
        alert.addToast({ message: 'Erro ao salvar cotação' });

        setLoading( false );
      }
    } else {
      alert.addToast({ message: 'Preencha corretamente' });

      setLoading( false );
    }
  };

  async function getConfigVigenciaCotacao() {
    const response = await api.getConfigVigenciaCotacao();

    if( response.statusText === 'OK') {
      const vigencia = startOfDay( addDays( new Date(), response.data.valor ));

      const dia = `0${ vigencia.getDate()}`.slice(-2);
      const mes = `0${ vigencia.getMonth() + 1}`.slice(-2);
      const ano = vigencia.getFullYear();

      setDataVigencia({ string: `${ dia }/${ mes }/${ ano }`, vigencia });
    } else {
      alert.addToast({ message: 'Falha ao buscar vigência cotação' });
    }
  };

  async function sendContacaoPDF() {
    const file = new File([sendEmail], 'Cotacao.pdf', { type: 'application/pdf'});

    const data = new FormData();

    data.append('document', file);

    await sendingEmail( data );

    setLoading( false );
    setOpen( false );

    setNome('');
    setEmail('');
    setSeguradora1( false );
    setSeguradora2( false );
    setSeguradora3( false );
    setSendEmail( null );

    window.scrollTo( 1, 1 );
    history.push('/app/cotacoes');
  };

  async function sendingEmail( data ) {
    console.log('ENVIANDO...');

    await api.sendCotacaoPDF( email, data );
  };

  useEffect(() => {
    getConfigVigenciaCotacao();
  }, []);

  useEffect(() => {
    if( sendEmail ) {
      sendContacaoPDF();
    }
  }, [ sendEmail ]);

  return(
    <Modal
      open={open}
      onClose={() => setOpen( false )}
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6">
            SALVAR COTAÇÕES
          </Typography>
        </Toolbar>

        <div style={{ padding: 10 }}>
          <div className={ classes.inputView }>
            <TextField
              className={ classes.input }
              value={`Cotação vigente até - ${ dataVigencia.string }`}
              size="small"
              label="Vigência"
              variant="outlined"
              disabled
            />
          </div>

          <div className={ classes.inputView }>
            <TextField
              className={ classes.input }
              value={ nome }
              onChange={ event => setNome( event.target.value )}
              size="small"
              label="Nome"
              variant="outlined"
            />
          </div>

          <div className={ classes.inputView }>
            <TextField
              className={ classes.input }
              value={ email }
              onChange={ event => setEmail( event.target.value )}
              size="small"
              label="Email"
              variant="outlined"
            />
          </div>

          <Typography style={{ padding: 10, fontWeight: 'bold' }} variant="body1">
            Lista de Cotações para seleção (Máximo de 3)
          </Typography>

          <div className={ classes.seguradoraView }>
            <Checkbox
              color="primary"
              checked={seguradora1}
              onChange={event => setSeguradora1( event.target.checked )}
            />

            <img className={ classes.imgSeguradora } src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${simulacao.data[0].dscaminhologo}`} alt=""/>

            <Typography variant="body1">
              { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( simulacao.data[0].valorPremioTotal )}
            </Typography>
          </div>

          <div className={ classes.seguradoraView}>
            <Checkbox
              color="primary"
              checked={seguradora2}
              onChange={event => setSeguradora2( event.target.checked )}
            />

            <img className={ classes.imgSeguradora } src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${simulacao.data[1].dscaminhologo}`} alt=""/>

            <Typography variant="body1">
              { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( simulacao.data[1].valorPremioTotal )}
            </Typography>
          </div>

          <div className={ classes.seguradoraView }>
            <Checkbox
              color="primary"
              checked={seguradora3}
              onChange={event => setSeguradora3( event.target.checked )}
            />

            <img className={ classes.imgSeguradora } src={`${process.env.REACT_APP_BASE_API_URL}/public/images/upload/seguradoras/${simulacao.data[2].dscaminhologo}`} alt=""/>

            <Typography variant="body1">
              { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( simulacao.data[2].valorPremioTotal )}
            </Typography>
          </div>

          <div className={ classes.actionsView }>
            <LoadingButton
              className={classes.button}
              onClick={() => setOpen(false)}
              color="primary"
            >
              Cancelar
            </LoadingButton>

            <LoadingButton
              className={classes.button}
              onClick={handleSaveCotacao}
              disabled={!(seguradora1 || seguradora2 || seguradora3) || nome === ''}
              loading={loading}
              variant="contained"
              color="primary"
            >
              Salvar
            </LoadingButton>

            {
              cotacaoResponse ? (
                <BlobProvider style={{ display: 'none' }} document={<MyDocument data={ cotacaoResponse }/>} fileName="Cotacao.pdf">
                  {({ blob, url, loading, error }) => {
                    if( !loading && cotacaoResponse ) {
                      setSendEmail( blob );

                      setCotacaoResponse( null );
                    }

                    return <></>;
                  }}
                </BlobProvider>
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </Paper>
    </Modal>
  )
};
