import React, { useRef, useEffect } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router-dom';

import { LOGIN_INITIAL, loginSchema } from '~/utils/constants';
import { FormikTextField, LoadingButton, FormikSwitch } from '~/components';
import { useStore } from '~/store';

import useStyles from './styles';

export function LoginForm({ className, ...rest }) {
  const { auth, alert } = useStore();

  const formikRef = useRef();

  const history = useHistory();
  const { state } = useLocation();

  const classes = useStyles();

  const formClassName = clsx([classes.form, className]);

  useEffect(() => {
    if (auth.usuario && formikRef.current) {
      formikRef.current.setFieldValue('email', auth.usuario);
      formikRef.current.setFieldValue('remember', true);
    }
  }, [auth.usuario]);

  async function handleSubmit(dados) {
    try {
      const { success, message } = await auth.signin(dados);

      const redirect = state?.redirect;

      if (success) {
        alert.addToast({ type: 'success', message: 'Bem-vindo' });

        const simulacao = localStorage.getItem('TDA_Seguros@Simulacao');

        if (redirect) {
          if( simulacao && redirect.pathname === '/app/seguro-incendio') {
            history.push(redirect.pathname, redirect.state);
          }
        }
      } else {
        if( message === 'Usuário já está logado!') {
          alert.addToast({ message });
        } else {
          alert.addToast({ message: 'Erro de autenticação, confira seus dados' });
        }
      }
    } catch {
      alert.addToast({ message: 'Erro inesperado, tente novamente' });
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={LOGIN_INITIAL}
      validationSchema={loginSchema}
      validateOnMount
      innerRef={formikRef}
    >
      {formik => (
        <Form className={formClassName} {...rest}>
          <FormikTextField
            type="email"
            name="email"
            label="E-mail"
            autoComplete="email"
          />
          <FormikTextField name="password" label="Senha" type="password" />
          <FormikSwitch
            className={classes.switch}
            name="remember"
            label="Guardar meu e-mail"
          />
          <LoadingButton
            className={classes.button}
            type="submit"
            disabled={!formik.isValid || auth.loading}
            loading={auth.loading}
            variant="contained"
            color="primary"
            size="large"
          >
            Entrar
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}
