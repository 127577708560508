import {
  BASICA,
  INTERMED,
  COMPLETA,
  tiposAssistencia,
  coberturasBasicaKey,
  coberturasIntermedKey,
  coberturasCompletaKey,
  precoComercialBasicaKey,
  precoComercialIntermedKey,
  precoComercialCompletaKey,
  precoResidencialBasicaKey,
  precoResidencialIntermedKey,
  precoResidencialCompletaKey
} from '~/utils/constants/assistencias';

const replaceAndTrim = text => {
  return text ? text.replace(/([\r\n]-\s)|^(-\s)/g, '').trim() : '';
};

const getPrecosKey = ({ tipoImovel }) => {
  return {
    [BASICA]: precoResidencialBasicaKey,
    [INTERMED]: precoResidencialIntermedKey,
    [COMPLETA]: precoResidencialCompletaKey
  };
};

export const getCoberturas = ({ dado, tipoAssistencia }) => {
  switch (tipoAssistencia.value) {
    case BASICA:
      return replaceAndTrim(dado[coberturasBasicaKey]);
    case INTERMED:
      return replaceAndTrim(dado[coberturasIntermedKey]);
    case COMPLETA:
      return replaceAndTrim(dado[coberturasCompletaKey]);
    default:
      return '';
  }
};

export const getPreco = ({ dado, tipoAssistencia, tipoImovel }) => {
  const precoKeys = getPrecosKey({ tipoImovel });

  switch (tipoAssistencia.value) {
    case BASICA:
      return dado[precoKeys[BASICA]];
    case INTERMED:
      return dado[precoKeys[INTERMED]];
    case COMPLETA:
      return dado[precoKeys[COMPLETA]];
    default:
      return 0;
  }
};

export const transformAssistencias = ({ data, tipoImovel }) =>
  data.map((dado, index) => {
    return {
      ...dado,
      id: index,
      planos: tiposAssistencia.reduce((acc, tipoAssistencia) => {
        const coberturas = getCoberturas({ dado, tipoAssistencia });
        const coberturasArr = coberturas
          .split(/[\n|\r|\n\r]/g)
          .filter(cobertura => !!cobertura.toString().trim())
          .map((cobertura, i) => ({
            id: i,
            label: cobertura.trim()
          }));
        const preco = getPreco({ dado, tipoAssistencia, tipoImovel });

        return {
          ...acc,
          [tipoAssistencia.value]: { coberturas, preco, coberturasArr }
        };
      }, {})
    };
  });
